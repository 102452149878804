var Cr = Object.defineProperty;
var Mr = (t, e, r) =>
  e in t
    ? Cr(t, e, { enumerable: !0, configurable: !0, writable: !0, value: r })
    : (t[e] = r);
var Z = (t, e, r) => (Mr(t, typeof e != "symbol" ? e + "" : e, r), r);
(function () {
  const e = document.createElement("link").relList;
  if (e && e.supports && e.supports("modulepreload")) return;
  for (const n of document.querySelectorAll('link[rel="modulepreload"]')) i(n);
  new MutationObserver((n) => {
    for (const o of n)
      if (o.type === "childList")
        for (const a of o.addedNodes)
          a.tagName === "LINK" && a.rel === "modulepreload" && i(a);
  }).observe(document, { childList: !0, subtree: !0 });
  function r(n) {
    const o = {};
    return (
      n.integrity && (o.integrity = n.integrity),
      n.referrerPolicy && (o.referrerPolicy = n.referrerPolicy),
      n.crossOrigin === "use-credentials"
        ? (o.credentials = "include")
        : n.crossOrigin === "anonymous"
        ? (o.credentials = "omit")
        : (o.credentials = "same-origin"),
      o
    );
  }
  function i(n) {
    if (n.ep) return;
    n.ep = !0;
    const o = r(n);
    fetch(n.href, o);
  }
})();
function at(t, e, r) {
  return Math.max(t, Math.min(e, r));
}
class kr {
  advance(e) {
    var s;
    if (!this.isRunning) return;
    let r = !1;
    if (this.lerp)
      (this.value =
        ((i = this.value),
        (n = this.to),
        (o = 60 * this.lerp),
        (a = e),
        (function (l, u, d) {
          return (1 - d) * l + d * u;
        })(i, n, 1 - Math.exp(-o * a)))),
        Math.round(this.value) === this.to &&
          ((this.value = this.to), (r = !0));
    else {
      this.currentTime += e;
      const l = at(0, this.currentTime / this.duration, 1);
      r = l >= 1;
      const u = r ? 1 : this.easing(l);
      this.value = this.from + (this.to - this.from) * u;
    }
    var i, n, o, a;
    (s = this.onUpdate) == null || s.call(this, this.value, r),
      r && this.stop();
  }
  stop() {
    this.isRunning = !1;
  }
  fromTo(
    e,
    r,
    {
      lerp: i = 0.1,
      duration: n = 1,
      easing: o = (l) => l,
      onStart: a,
      onUpdate: s,
    }
  ) {
    (this.from = this.value = e),
      (this.to = r),
      (this.lerp = i),
      (this.duration = n),
      (this.easing = o),
      (this.currentTime = 0),
      (this.isRunning = !0),
      a == null || a(),
      (this.onUpdate = s);
  }
}
class Dr {
  constructor({ wrapper: e, content: r, autoResize: i = !0 } = {}) {
    Z(this, "resize", () => {
      this.onWrapperResize(), this.onContentResize();
    });
    Z(this, "onWrapperResize", () => {
      this.wrapper === window
        ? ((this.width = window.innerWidth), (this.height = window.innerHeight))
        : ((this.width = this.wrapper.clientWidth),
          (this.height = this.wrapper.clientHeight));
    });
    Z(this, "onContentResize", () => {
      (this.scrollHeight = this.content.scrollHeight),
        (this.scrollWidth = this.content.scrollWidth);
    });
    if (((this.wrapper = e), (this.content = r), i)) {
      const n = (function (o, a) {
        let s;
        return function () {
          let l = arguments,
            u = this;
          clearTimeout(s),
            (s = setTimeout(function () {
              o.apply(u, l);
            }, a));
        };
      })(this.resize, 250);
      this.wrapper !== window &&
        ((this.wrapperResizeObserver = new ResizeObserver(n)),
        this.wrapperResizeObserver.observe(this.wrapper)),
        (this.contentResizeObserver = new ResizeObserver(n)),
        this.contentResizeObserver.observe(this.content);
    }
    this.resize();
  }
  destroy() {
    var e, r;
    (e = this.wrapperResizeObserver) == null || e.disconnect(),
      (r = this.contentResizeObserver) == null || r.disconnect();
  }
  get limit() {
    return {
      x: this.scrollWidth - this.width,
      y: this.scrollHeight - this.height,
    };
  }
}
class or {
  constructor() {
    this.events = {};
  }
  emit(e, ...r) {
    let i = this.events[e] || [];
    for (let n = 0, o = i.length; n < o; n++) i[n](...r);
  }
  on(e, r) {
    var i;
    return (
      ((i = this.events[e]) != null && i.push(r)) || (this.events[e] = [r]),
      () => {
        var n;
        this.events[e] =
          (n = this.events[e]) == null ? void 0 : n.filter((o) => r !== o);
      }
    );
  }
  off(e, r) {
    var i;
    this.events[e] =
      (i = this.events[e]) == null ? void 0 : i.filter((n) => r !== n);
  }
  destroy() {
    this.events = {};
  }
}
class Pr {
  constructor(
    e,
    { wheelMultiplier: r = 1, touchMultiplier: i = 2, normalizeWheel: n = !1 }
  ) {
    Z(this, "onTouchStart", (e) => {
      const { clientX: r, clientY: i } = e.targetTouches
        ? e.targetTouches[0]
        : e;
      (this.touchStart.x = r),
        (this.touchStart.y = i),
        (this.lastDelta = { x: 0, y: 0 }),
        this.emitter.emit("scroll", { deltaX: 0, deltaY: 0, event: e });
    });
    Z(this, "onTouchMove", (e) => {
      const { clientX: r, clientY: i } = e.targetTouches
          ? e.targetTouches[0]
          : e,
        n = -(r - this.touchStart.x) * this.touchMultiplier,
        o = -(i - this.touchStart.y) * this.touchMultiplier;
      (this.touchStart.x = r),
        (this.touchStart.y = i),
        (this.lastDelta = { x: n, y: o }),
        this.emitter.emit("scroll", { deltaX: n, deltaY: o, event: e });
    });
    Z(this, "onTouchEnd", (e) => {
      this.emitter.emit("scroll", {
        deltaX: this.lastDelta.x,
        deltaY: this.lastDelta.y,
        event: e,
      });
    });
    Z(this, "onWheel", (e) => {
      let { deltaX: r, deltaY: i } = e;
      this.normalizeWheel && ((r = at(-100, r, 100)), (i = at(-100, i, 100))),
        (r *= this.wheelMultiplier),
        (i *= this.wheelMultiplier),
        this.emitter.emit("scroll", { deltaX: r, deltaY: i, event: e });
    });
    (this.element = e),
      (this.wheelMultiplier = r),
      (this.touchMultiplier = i),
      (this.normalizeWheel = n),
      (this.touchStart = { x: null, y: null }),
      (this.emitter = new or()),
      this.element.addEventListener("wheel", this.onWheel, { passive: !1 }),
      this.element.addEventListener("touchstart", this.onTouchStart, {
        passive: !1,
      }),
      this.element.addEventListener("touchmove", this.onTouchMove, {
        passive: !1,
      }),
      this.element.addEventListener("touchend", this.onTouchEnd, {
        passive: !1,
      });
  }
  on(e, r) {
    return this.emitter.on(e, r);
  }
  destroy() {
    this.emitter.destroy(),
      this.element.removeEventListener("wheel", this.onWheel, { passive: !1 }),
      this.element.removeEventListener("touchstart", this.onTouchStart, {
        passive: !1,
      }),
      this.element.removeEventListener("touchmove", this.onTouchMove, {
        passive: !1,
      }),
      this.element.removeEventListener("touchend", this.onTouchEnd, {
        passive: !1,
      });
  }
}
class Rr {
  constructor({
    wrapper: e = window,
    content: r = document.documentElement,
    wheelEventsTarget: i = e,
    eventsTarget: n = i,
    smoothWheel: o = !0,
    smoothTouch: a = !1,
    syncTouch: s = !1,
    syncTouchLerp: l = 0.075,
    touchInertiaMultiplier: u = 35,
    duration: d,
    easing: p = (c) => Math.min(1, 1.001 - Math.pow(2, -10 * c)),
    lerp: g = !d && 0.1,
    infinite: v = !1,
    orientation: E = "vertical",
    gestureOrientation: w = "vertical",
    touchMultiplier: S = 1,
    wheelMultiplier: h = 1,
    normalizeWheel: y = !1,
    autoResize: L = !0,
  } = {}) {
    Z(this, "onVirtualScroll", ({ deltaX: e, deltaY: r, event: i }) => {
      if (i.ctrlKey) return;
      const n = i.type.includes("touch"),
        o = i.type.includes("wheel");
      if (
        (this.options.smoothTouch || this.options.syncTouch) &&
        n &&
        i.type === "touchstart"
      )
        return void this.reset();
      const a = e === 0 && r === 0,
        s =
          (this.options.gestureOrientation === "vertical" && r === 0) ||
          (this.options.gestureOrientation === "horizontal" && e === 0);
      if (a || s) return;
      let l = i.composedPath();
      if (
        ((l = l.slice(0, l.indexOf(this.rootElement))),
        l.find((g) => {
          var v, E, w, S;
          return (
            ((v = g.hasAttribute) == null
              ? void 0
              : v.call(g, "data-lenis-prevent")) ||
            (n &&
              ((E = g.hasAttribute) == null
                ? void 0
                : E.call(g, "data-lenis-prevent-touch"))) ||
            (o &&
              ((w = g.hasAttribute) == null
                ? void 0
                : w.call(g, "data-lenis-prevent-wheel"))) ||
            ((S = g.classList) == null ? void 0 : S.contains("lenis"))
          );
        }))
      )
        return;
      if (this.isStopped || this.isLocked) return void i.preventDefault();
      if (
        ((this.isSmooth =
          ((this.options.smoothTouch || this.options.syncTouch) && n) ||
          (this.options.smoothWheel && o)),
        !this.isSmooth)
      )
        return (this.isScrolling = !1), void this.animate.stop();
      i.preventDefault();
      let u = r;
      this.options.gestureOrientation === "both"
        ? (u = Math.abs(r) > Math.abs(e) ? r : e)
        : this.options.gestureOrientation === "horizontal" && (u = e);
      const d = n && this.options.syncTouch,
        p = n && i.type === "touchend" && Math.abs(u) > 5;
      p && (u = this.velocity * this.options.touchInertiaMultiplier),
        this.scrollTo(this.targetScroll + u, {
          programmatic: !1,
          ...(d
            ? { lerp: p ? this.options.syncTouchLerp : 1 }
            : {
                lerp: this.options.lerp,
                duration: this.options.duration,
                easing: this.options.easing,
              }),
        });
    });
    Z(this, "onNativeScroll", () => {
      if (!this.__preventNextScrollEvent && !this.isScrolling) {
        const e = this.animatedScroll;
        (this.animatedScroll = this.targetScroll = this.actualScroll),
          (this.velocity = 0),
          (this.direction = Math.sign(this.animatedScroll - e)),
          this.emit();
      }
    });
    (window.lenisVersion = "1.0.33"),
      (e !== document.documentElement && e !== document.body) || (e = window),
      (this.options = {
        wrapper: e,
        content: r,
        wheelEventsTarget: i,
        eventsTarget: n,
        smoothWheel: o,
        smoothTouch: a,
        syncTouch: s,
        syncTouchLerp: l,
        touchInertiaMultiplier: u,
        duration: d,
        easing: p,
        lerp: g,
        infinite: v,
        gestureOrientation: w,
        orientation: E,
        touchMultiplier: S,
        wheelMultiplier: h,
        normalizeWheel: y,
        autoResize: L,
      }),
      (this.animate = new kr()),
      (this.emitter = new or()),
      (this.dimensions = new Dr({ wrapper: e, content: r, autoResize: L })),
      this.toggleClass("lenis", !0),
      (this.velocity = 0),
      (this.isLocked = !1),
      (this.isStopped = !1),
      (this.isSmooth = s || o || a),
      (this.isScrolling = !1),
      (this.targetScroll = this.animatedScroll = this.actualScroll),
      this.options.wrapper.addEventListener("scroll", this.onNativeScroll, {
        passive: !1,
      }),
      (this.virtualScroll = new Pr(n, {
        touchMultiplier: S,
        wheelMultiplier: h,
        normalizeWheel: y,
      })),
      this.virtualScroll.on("scroll", this.onVirtualScroll);
  }
  destroy() {
    this.emitter.destroy(),
      this.options.wrapper.removeEventListener("scroll", this.onNativeScroll, {
        passive: !1,
      }),
      this.virtualScroll.destroy(),
      this.dimensions.destroy(),
      this.toggleClass("lenis", !1),
      this.toggleClass("lenis-smooth", !1),
      this.toggleClass("lenis-scrolling", !1),
      this.toggleClass("lenis-stopped", !1),
      this.toggleClass("lenis-locked", !1);
  }
  on(e, r) {
    return this.emitter.on(e, r);
  }
  off(e, r) {
    return this.emitter.off(e, r);
  }
  setScroll(e) {
    this.isHorizontal
      ? (this.rootElement.scrollLeft = e)
      : (this.rootElement.scrollTop = e);
  }
  resize() {
    this.dimensions.resize();
  }
  emit() {
    this.emitter.emit("scroll", this);
  }
  reset() {
    (this.isLocked = !1),
      (this.isScrolling = !1),
      (this.animatedScroll = this.targetScroll = this.actualScroll),
      (this.velocity = 0),
      this.animate.stop();
  }
  start() {
    (this.isStopped = !1), this.reset();
  }
  stop() {
    (this.isStopped = !0), this.animate.stop(), this.reset();
  }
  raf(e) {
    const r = e - (this.time || e);
    (this.time = e), this.animate.advance(0.001 * r);
  }
  scrollTo(
    e,
    {
      offset: r = 0,
      immediate: i = !1,
      lock: n = !1,
      duration: o = this.options.duration,
      easing: a = this.options.easing,
      lerp: s = !o && this.options.lerp,
      onComplete: l = null,
      force: u = !1,
      programmatic: d = !0,
    } = {}
  ) {
    if ((!this.isStopped && !this.isLocked) || u) {
      if (["top", "left", "start"].includes(e)) e = 0;
      else if (["bottom", "right", "end"].includes(e)) e = this.limit;
      else {
        let p;
        if (
          (typeof e == "string"
            ? (p = document.querySelector(e))
            : e != null && e.nodeType && (p = e),
          p)
        ) {
          if (this.options.wrapper !== window) {
            const v = this.options.wrapper.getBoundingClientRect();
            r -= this.isHorizontal ? v.left : v.top;
          }
          const g = p.getBoundingClientRect();
          e = (this.isHorizontal ? g.left : g.top) + this.animatedScroll;
        }
      }
      if (typeof e == "number") {
        if (
          ((e += r),
          (e = Math.round(e)),
          this.options.infinite
            ? d && (this.targetScroll = this.animatedScroll = this.scroll)
            : (e = at(0, e, this.limit)),
          i)
        )
          return (
            (this.animatedScroll = this.targetScroll = e),
            this.setScroll(this.scroll),
            this.reset(),
            void (l == null ? void 0 : l(this))
          );
        if (!d) {
          if (e === this.targetScroll) return;
          this.targetScroll = e;
        }
        this.animate.fromTo(this.animatedScroll, e, {
          duration: o,
          easing: a,
          lerp: s,
          onStart: () => {
            n && (this.isLocked = !0), (this.isScrolling = !0);
          },
          onUpdate: (p, g) => {
            (this.isScrolling = !0),
              (this.velocity = p - this.animatedScroll),
              (this.direction = Math.sign(this.velocity)),
              (this.animatedScroll = p),
              this.setScroll(this.scroll),
              d && (this.targetScroll = p),
              g || this.emit(),
              g &&
                (this.reset(),
                this.emit(),
                l == null || l(this),
                (this.__preventNextScrollEvent = !0),
                requestAnimationFrame(() => {
                  delete this.__preventNextScrollEvent;
                }));
          },
        });
      }
    }
  }
  get rootElement() {
    return this.options.wrapper === window
      ? document.documentElement
      : this.options.wrapper;
  }
  get limit() {
    return this.dimensions.limit[this.isHorizontal ? "x" : "y"];
  }
  get isHorizontal() {
    return this.options.orientation === "horizontal";
  }
  get actualScroll() {
    return this.isHorizontal
      ? this.rootElement.scrollLeft
      : this.rootElement.scrollTop;
  }
  get scroll() {
    return this.options.infinite
      ? ((e = this.animatedScroll), (r = this.limit), ((e % r) + r) % r)
      : this.animatedScroll;
    var e, r;
  }
  get progress() {
    return this.limit === 0 ? 1 : this.scroll / this.limit;
  }
  get isSmooth() {
    return this.__isSmooth;
  }
  set isSmooth(e) {
    this.__isSmooth !== e &&
      ((this.__isSmooth = e), this.toggleClass("lenis-smooth", e));
  }
  get isScrolling() {
    return this.__isScrolling;
  }
  set isScrolling(e) {
    this.__isScrolling !== e &&
      ((this.__isScrolling = e), this.toggleClass("lenis-scrolling", e));
  }
  get isStopped() {
    return this.__isStopped;
  }
  set isStopped(e) {
    this.__isStopped !== e &&
      ((this.__isStopped = e), this.toggleClass("lenis-stopped", e));
  }
  get isLocked() {
    return this.__isLocked;
  }
  set isLocked(e) {
    this.__isLocked !== e &&
      ((this.__isLocked = e), this.toggleClass("lenis-locked", e));
  }
  get className() {
    let e = "lenis";
    return (
      this.isStopped && (e += " lenis-stopped"),
      this.isLocked && (e += " lenis-locked"),
      this.isScrolling && (e += " lenis-scrolling"),
      this.isSmooth && (e += " lenis-smooth"),
      e
    );
  }
  toggleClass(e, r) {
    this.rootElement.classList.toggle(e, r),
      this.emitter.emit("className change", this);
  }
}
function Hr(t, e) {
  for (var r = 0; r < e.length; r++) {
    var i = e[r];
    (i.enumerable = i.enumerable || !1),
      (i.configurable = !0),
      "value" in i && (i.writable = !0),
      Object.defineProperty(t, i.key, i);
  }
}
function rt(t) {
  return (
    (function (e) {
      if (Array.isArray(e)) return ht(e);
    })(t) ||
    (function (e) {
      if (typeof Symbol < "u" && Symbol.iterator in Object(e))
        return Array.from(e);
    })(t) ||
    (function (e, r) {
      if (e) {
        if (typeof e == "string") return ht(e, r);
        var i = Object.prototype.toString.call(e).slice(8, -1);
        if (
          (i === "Object" && e.constructor && (i = e.constructor.name),
          i === "Map" || i === "Set")
        )
          return Array.from(e);
        if (
          i === "Arguments" ||
          /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i)
        )
          return ht(e, r);
      }
    })(t) ||
    (function () {
      throw new TypeError(`Invalid attempt to spread non-iterable instance.
In order to be iterable, non-array objects must have a [Symbol.iterator]() method.`);
    })()
  );
}
function ht(t, e) {
  (e == null || e > t.length) && (e = t.length);
  for (var r = 0, i = new Array(e); r < e; r++) i[r] = t[r];
  return i;
}
var $t,
  vt,
  ve,
  mt,
  Bt,
  Ot =
    (($t = [
      "a[href]",
      "area[href]",
      'input:not([disabled]):not([type="hidden"]):not([aria-hidden])',
      "select:not([disabled]):not([aria-hidden])",
      "textarea:not([disabled]):not([aria-hidden])",
      "button:not([disabled]):not([aria-hidden])",
      "iframe",
      "object",
      "embed",
      "[contenteditable]",
      '[tabindex]:not([tabindex^="-"])',
    ]),
    (vt = (function () {
      function t(i) {
        var n = i.targetModal,
          o = i.triggers,
          a = o === void 0 ? [] : o,
          s = i.onShow,
          l = s === void 0 ? function () {} : s,
          u = i.onClose,
          d = u === void 0 ? function () {} : u,
          p = i.openTrigger,
          g = p === void 0 ? "data-micromodal-trigger" : p,
          v = i.closeTrigger,
          E = v === void 0 ? "data-micromodal-close" : v,
          w = i.openClass,
          S = w === void 0 ? "is-open" : w,
          h = i.disableScroll,
          y = h !== void 0 && h,
          L = i.disableFocus,
          c = L !== void 0 && L,
          _ = i.awaitCloseAnimation,
          m = _ !== void 0 && _,
          T = i.awaitOpenAnimation,
          q = T !== void 0 && T,
          k = i.debugMode,
          C = k !== void 0 && k;
        (function (P, M) {
          if (!(P instanceof M))
            throw new TypeError("Cannot call a class as a function");
        })(this, t),
          (this.modal = document.getElementById(n)),
          (this.config = {
            debugMode: C,
            disableScroll: y,
            openTrigger: g,
            closeTrigger: E,
            openClass: S,
            onShow: l,
            onClose: d,
            awaitCloseAnimation: m,
            awaitOpenAnimation: q,
            disableFocus: c,
          }),
          a.length > 0 && this.registerTriggers.apply(this, rt(a)),
          (this.onClick = this.onClick.bind(this)),
          (this.onKeydown = this.onKeydown.bind(this));
      }
      var e, r;
      return (
        (e = t),
        (r = [
          {
            key: "registerTriggers",
            value: function () {
              for (
                var i = this, n = arguments.length, o = new Array(n), a = 0;
                a < n;
                a++
              )
                o[a] = arguments[a];
              o.filter(Boolean).forEach(function (s) {
                s.addEventListener("click", function (l) {
                  return i.showModal(l);
                });
              });
            },
          },
          {
            key: "showModal",
            value: function () {
              var i = this,
                n =
                  arguments.length > 0 && arguments[0] !== void 0
                    ? arguments[0]
                    : null;
              if (
                ((this.activeElement = document.activeElement),
                this.modal.setAttribute("aria-hidden", "false"),
                this.modal.classList.add(this.config.openClass),
                this.scrollBehaviour("disable"),
                this.addEventListeners(),
                this.config.awaitOpenAnimation)
              ) {
                var o = function a() {
                  i.modal.removeEventListener("animationend", a, !1),
                    i.setFocusToFirstNode();
                };
                this.modal.addEventListener("animationend", o, !1);
              } else this.setFocusToFirstNode();
              this.config.onShow(this.modal, this.activeElement, n);
            },
          },
          {
            key: "closeModal",
            value: function () {
              var i =
                  arguments.length > 0 && arguments[0] !== void 0
                    ? arguments[0]
                    : null,
                n = this.modal;
              if (
                (this.modal.setAttribute("aria-hidden", "true"),
                this.removeEventListeners(),
                this.scrollBehaviour("enable"),
                this.activeElement &&
                  this.activeElement.focus &&
                  this.activeElement.focus(),
                this.config.onClose(this.modal, this.activeElement, i),
                this.config.awaitCloseAnimation)
              ) {
                var o = this.config.openClass;
                this.modal.addEventListener(
                  "animationend",
                  function a() {
                    n.classList.remove(o),
                      n.removeEventListener("animationend", a, !1);
                  },
                  !1
                );
              } else n.classList.remove(this.config.openClass);
            },
          },
          {
            key: "closeModalById",
            value: function (i) {
              (this.modal = document.getElementById(i)),
                this.modal && this.closeModal();
            },
          },
          {
            key: "scrollBehaviour",
            value: function (i) {
              if (this.config.disableScroll) {
                var n = document.querySelector("body");
                switch (i) {
                  case "enable":
                    Object.assign(n.style, { overflow: "" });
                    break;
                  case "disable":
                    Object.assign(n.style, { overflow: "hidden" });
                }
              }
            },
          },
          {
            key: "addEventListeners",
            value: function () {
              this.modal.addEventListener("touchstart", this.onClick),
                this.modal.addEventListener("click", this.onClick),
                document.addEventListener("keydown", this.onKeydown);
            },
          },
          {
            key: "removeEventListeners",
            value: function () {
              this.modal.removeEventListener("touchstart", this.onClick),
                this.modal.removeEventListener("click", this.onClick),
                document.removeEventListener("keydown", this.onKeydown);
            },
          },
          {
            key: "onClick",
            value: function (i) {
              (i.target.hasAttribute(this.config.closeTrigger) ||
                i.target.parentNode.hasAttribute(this.config.closeTrigger)) &&
                (i.preventDefault(), i.stopPropagation(), this.closeModal(i));
            },
          },
          {
            key: "onKeydown",
            value: function (i) {
              i.keyCode === 27 && this.closeModal(i),
                i.keyCode === 9 && this.retainFocus(i);
            },
          },
          {
            key: "getFocusableNodes",
            value: function () {
              var i = this.modal.querySelectorAll($t);
              return Array.apply(void 0, rt(i));
            },
          },
          {
            key: "setFocusToFirstNode",
            value: function () {
              var i = this;
              if (!this.config.disableFocus) {
                var n = this.getFocusableNodes();
                if (n.length !== 0) {
                  var o = n.filter(function (a) {
                    return !a.hasAttribute(i.config.closeTrigger);
                  });
                  o.length > 0 && o[0].focus(), o.length === 0 && n[0].focus();
                }
              }
            },
          },
          {
            key: "retainFocus",
            value: function (i) {
              var n = this.getFocusableNodes();
              if (n.length !== 0)
                if (
                  ((n = n.filter(function (a) {
                    return a.offsetParent !== null;
                  })),
                  this.modal.contains(document.activeElement))
                ) {
                  var o = n.indexOf(document.activeElement);
                  i.shiftKey &&
                    o === 0 &&
                    (n[n.length - 1].focus(), i.preventDefault()),
                    !i.shiftKey &&
                      n.length > 0 &&
                      o === n.length - 1 &&
                      (n[0].focus(), i.preventDefault());
                } else n[0].focus();
            },
          },
        ]) && Hr(e.prototype, r),
        t
      );
    })()),
    (ve = null),
    (mt = function (t) {
      if (!document.getElementById(t))
        return (
          console.warn(
            "MicroModal: ❗Seems like you have missed %c'".concat(t, "'"),
            "background-color: #f8f9fa;color: #50596c;font-weight: bold;",
            "ID somewhere in your code. Refer example below to resolve it."
          ),
          console.warn(
            "%cExample:",
            "background-color: #f8f9fa;color: #50596c;font-weight: bold;",
            '<div class="modal" id="'.concat(t, '"></div>')
          ),
          !1
        );
    }),
    (Bt = function (t, e) {
      if (
        ((function (i) {
          i.length <= 0 &&
            (console.warn(
              "MicroModal: ❗Please specify at least one %c'micromodal-trigger'",
              "background-color: #f8f9fa;color: #50596c;font-weight: bold;",
              "data attribute."
            ),
            console.warn(
              "%cExample:",
              "background-color: #f8f9fa;color: #50596c;font-weight: bold;",
              '<a href="#" data-micromodal-trigger="my-modal"></a>'
            ));
        })(t),
        !e)
      )
        return !0;
      for (var r in e) mt(r);
      return !0;
    }),
    {
      init: function (t) {
        var e = Object.assign(
            {},
            { openTrigger: "data-micromodal-trigger" },
            t
          ),
          r = rt(document.querySelectorAll("[".concat(e.openTrigger, "]"))),
          i = (function (a, s) {
            var l = [];
            return (
              a.forEach(function (u) {
                var d = u.attributes[s].value;
                l[d] === void 0 && (l[d] = []), l[d].push(u);
              }),
              l
            );
          })(r, e.openTrigger);
        if (e.debugMode !== !0 || Bt(r, i) !== !1)
          for (var n in i) {
            var o = i[n];
            (e.targetModal = n), (e.triggers = rt(o)), (ve = new vt(e));
          }
      },
      show: function (t, e) {
        var r = e || {};
        (r.targetModal = t),
          (r.debugMode === !0 && mt(t) === !1) ||
            (ve && ve.removeEventListeners(), (ve = new vt(r)).showModal());
      },
      close: function (t) {
        t ? ve.closeModalById(t) : ve.closeModal();
      },
    });
typeof window < "u" && (window.MicroModal = Ot);
var B = "top",
  V = "bottom",
  Y = "right",
  N = "left",
  qt = "auto",
  je = [B, V, Y, N],
  Te = "start",
  We = "end",
  Ir = "clippingParents",
  sr = "viewport",
  He = "popper",
  $r = "reference",
  Nt = je.reduce(function (t, e) {
    return t.concat([e + "-" + Te, e + "-" + We]);
  }, []),
  ar = [].concat(je, [qt]).reduce(function (t, e) {
    return t.concat([e, e + "-" + Te, e + "-" + We]);
  }, []),
  Br = "beforeRead",
  Nr = "read",
  Wr = "afterRead",
  Fr = "beforeMain",
  jr = "main",
  zr = "afterMain",
  Ur = "beforeWrite",
  Vr = "write",
  Yr = "afterWrite",
  Xr = [Br, Nr, Wr, Fr, jr, zr, Ur, Vr, Yr];
function re(t) {
  return t ? (t.nodeName || "").toLowerCase() : null;
}
function j(t) {
  if (t == null) return window;
  if (t.toString() !== "[object Window]") {
    var e = t.ownerDocument;
    return (e && e.defaultView) || window;
  }
  return t;
}
function ye(t) {
  var e = j(t).Element;
  return t instanceof e || t instanceof Element;
}
function U(t) {
  var e = j(t).HTMLElement;
  return t instanceof e || t instanceof HTMLElement;
}
function Ct(t) {
  if (typeof ShadowRoot > "u") return !1;
  var e = j(t).ShadowRoot;
  return t instanceof e || t instanceof ShadowRoot;
}
function Gr(t) {
  var e = t.state;
  Object.keys(e.elements).forEach(function (r) {
    var i = e.styles[r] || {},
      n = e.attributes[r] || {},
      o = e.elements[r];
    !U(o) ||
      !re(o) ||
      (Object.assign(o.style, i),
      Object.keys(n).forEach(function (a) {
        var s = n[a];
        s === !1 ? o.removeAttribute(a) : o.setAttribute(a, s === !0 ? "" : s);
      }));
  });
}
function Kr(t) {
  var e = t.state,
    r = {
      popper: {
        position: e.options.strategy,
        left: "0",
        top: "0",
        margin: "0",
      },
      arrow: { position: "absolute" },
      reference: {},
    };
  return (
    Object.assign(e.elements.popper.style, r.popper),
    (e.styles = r),
    e.elements.arrow && Object.assign(e.elements.arrow.style, r.arrow),
    function () {
      Object.keys(e.elements).forEach(function (i) {
        var n = e.elements[i],
          o = e.attributes[i] || {},
          a = Object.keys(e.styles.hasOwnProperty(i) ? e.styles[i] : r[i]),
          s = a.reduce(function (l, u) {
            return (l[u] = ""), l;
          }, {});
        !U(n) ||
          !re(n) ||
          (Object.assign(n.style, s),
          Object.keys(o).forEach(function (l) {
            n.removeAttribute(l);
          }));
      });
    }
  );
}
const lr = {
  name: "applyStyles",
  enabled: !0,
  phase: "write",
  fn: Gr,
  effect: Kr,
  requires: ["computeStyles"],
};
function te(t) {
  return t.split("-")[0];
}
var ge = Math.max,
  lt = Math.min,
  xe = Math.round;
function At() {
  var t = navigator.userAgentData;
  return t != null && t.brands && Array.isArray(t.brands)
    ? t.brands
        .map(function (e) {
          return e.brand + "/" + e.version;
        })
        .join(" ")
    : navigator.userAgent;
}
function cr() {
  return !/^((?!chrome|android).)*safari/i.test(At());
}
function Oe(t, e, r) {
  e === void 0 && (e = !1), r === void 0 && (r = !1);
  var i = t.getBoundingClientRect(),
    n = 1,
    o = 1;
  e &&
    U(t) &&
    ((n = (t.offsetWidth > 0 && xe(i.width) / t.offsetWidth) || 1),
    (o = (t.offsetHeight > 0 && xe(i.height) / t.offsetHeight) || 1));
  var a = ye(t) ? j(t) : window,
    s = a.visualViewport,
    l = !cr() && r,
    u = (i.left + (l && s ? s.offsetLeft : 0)) / n,
    d = (i.top + (l && s ? s.offsetTop : 0)) / o,
    p = i.width / n,
    g = i.height / o;
  return {
    width: p,
    height: g,
    top: d,
    right: u + p,
    bottom: d + g,
    left: u,
    x: u,
    y: d,
  };
}
function Mt(t) {
  var e = Oe(t),
    r = t.offsetWidth,
    i = t.offsetHeight;
  return (
    Math.abs(e.width - r) <= 1 && (r = e.width),
    Math.abs(e.height - i) <= 1 && (i = e.height),
    { x: t.offsetLeft, y: t.offsetTop, width: r, height: i }
  );
}
function ur(t, e) {
  var r = e.getRootNode && e.getRootNode();
  if (t.contains(e)) return !0;
  if (r && Ct(r)) {
    var i = e;
    do {
      if (i && t.isSameNode(i)) return !0;
      i = i.parentNode || i.host;
    } while (i);
  }
  return !1;
}
function ae(t) {
  return j(t).getComputedStyle(t);
}
function Jr(t) {
  return ["table", "td", "th"].indexOf(re(t)) >= 0;
}
function ue(t) {
  return ((ye(t) ? t.ownerDocument : t.document) || window.document)
    .documentElement;
}
function ut(t) {
  return re(t) === "html"
    ? t
    : t.assignedSlot || t.parentNode || (Ct(t) ? t.host : null) || ue(t);
}
function Wt(t) {
  return !U(t) || ae(t).position === "fixed" ? null : t.offsetParent;
}
function Qr(t) {
  var e = /firefox/i.test(At()),
    r = /Trident/i.test(At());
  if (r && U(t)) {
    var i = ae(t);
    if (i.position === "fixed") return null;
  }
  var n = ut(t);
  for (Ct(n) && (n = n.host); U(n) && ["html", "body"].indexOf(re(n)) < 0; ) {
    var o = ae(n);
    if (
      o.transform !== "none" ||
      o.perspective !== "none" ||
      o.contain === "paint" ||
      ["transform", "perspective"].indexOf(o.willChange) !== -1 ||
      (e && o.willChange === "filter") ||
      (e && o.filter && o.filter !== "none")
    )
      return n;
    n = n.parentNode;
  }
  return null;
}
function ze(t) {
  for (var e = j(t), r = Wt(t); r && Jr(r) && ae(r).position === "static"; )
    r = Wt(r);
  return r &&
    (re(r) === "html" || (re(r) === "body" && ae(r).position === "static"))
    ? e
    : r || Qr(t) || e;
}
function kt(t) {
  return ["top", "bottom"].indexOf(t) >= 0 ? "x" : "y";
}
function $e(t, e, r) {
  return ge(t, lt(e, r));
}
function Zr(t, e, r) {
  var i = $e(t, e, r);
  return i > r ? r : i;
}
function dr() {
  return { top: 0, right: 0, bottom: 0, left: 0 };
}
function fr(t) {
  return Object.assign({}, dr(), t);
}
function pr(t, e) {
  return e.reduce(function (r, i) {
    return (r[i] = t), r;
  }, {});
}
var ei = function (e, r) {
  return (
    (e =
      typeof e == "function"
        ? e(Object.assign({}, r.rects, { placement: r.placement }))
        : e),
    fr(typeof e != "number" ? e : pr(e, je))
  );
};
function ti(t) {
  var e,
    r = t.state,
    i = t.name,
    n = t.options,
    o = r.elements.arrow,
    a = r.modifiersData.popperOffsets,
    s = te(r.placement),
    l = kt(s),
    u = [N, Y].indexOf(s) >= 0,
    d = u ? "height" : "width";
  if (!(!o || !a)) {
    var p = ei(n.padding, r),
      g = Mt(o),
      v = l === "y" ? B : N,
      E = l === "y" ? V : Y,
      w =
        r.rects.reference[d] + r.rects.reference[l] - a[l] - r.rects.popper[d],
      S = a[l] - r.rects.reference[l],
      h = ze(o),
      y = h ? (l === "y" ? h.clientHeight || 0 : h.clientWidth || 0) : 0,
      L = w / 2 - S / 2,
      c = p[v],
      _ = y - g[d] - p[E],
      m = y / 2 - g[d] / 2 + L,
      T = $e(c, m, _),
      q = l;
    r.modifiersData[i] = ((e = {}), (e[q] = T), (e.centerOffset = T - m), e);
  }
}
function ri(t) {
  var e = t.state,
    r = t.options,
    i = r.element,
    n = i === void 0 ? "[data-popper-arrow]" : i;
  n != null &&
    ((typeof n == "string" && ((n = e.elements.popper.querySelector(n)), !n)) ||
      (ur(e.elements.popper, n) && (e.elements.arrow = n)));
}
const ii = {
  name: "arrow",
  enabled: !0,
  phase: "main",
  fn: ti,
  effect: ri,
  requires: ["popperOffsets"],
  requiresIfExists: ["preventOverflow"],
};
function qe(t) {
  return t.split("-")[1];
}
var ni = { top: "auto", right: "auto", bottom: "auto", left: "auto" };
function oi(t, e) {
  var r = t.x,
    i = t.y,
    n = e.devicePixelRatio || 1;
  return { x: xe(r * n) / n || 0, y: xe(i * n) / n || 0 };
}
function Ft(t) {
  var e,
    r = t.popper,
    i = t.popperRect,
    n = t.placement,
    o = t.variation,
    a = t.offsets,
    s = t.position,
    l = t.gpuAcceleration,
    u = t.adaptive,
    d = t.roundOffsets,
    p = t.isFixed,
    g = a.x,
    v = g === void 0 ? 0 : g,
    E = a.y,
    w = E === void 0 ? 0 : E,
    S = typeof d == "function" ? d({ x: v, y: w }) : { x: v, y: w };
  (v = S.x), (w = S.y);
  var h = a.hasOwnProperty("x"),
    y = a.hasOwnProperty("y"),
    L = N,
    c = B,
    _ = window;
  if (u) {
    var m = ze(r),
      T = "clientHeight",
      q = "clientWidth";
    if (
      (m === j(r) &&
        ((m = ue(r)),
        ae(m).position !== "static" &&
          s === "absolute" &&
          ((T = "scrollHeight"), (q = "scrollWidth"))),
      (m = m),
      n === B || ((n === N || n === Y) && o === We))
    ) {
      c = V;
      var k = p && m === _ && _.visualViewport ? _.visualViewport.height : m[T];
      (w -= k - i.height), (w *= l ? 1 : -1);
    }
    if (n === N || ((n === B || n === V) && o === We)) {
      L = Y;
      var C = p && m === _ && _.visualViewport ? _.visualViewport.width : m[q];
      (v -= C - i.width), (v *= l ? 1 : -1);
    }
  }
  var P = Object.assign({ position: s }, u && ni),
    M = d === !0 ? oi({ x: v, y: w }, j(r)) : { x: v, y: w };
  if (((v = M.x), (w = M.y), l)) {
    var D;
    return Object.assign(
      {},
      P,
      ((D = {}),
      (D[c] = y ? "0" : ""),
      (D[L] = h ? "0" : ""),
      (D.transform =
        (_.devicePixelRatio || 1) <= 1
          ? "translate(" + v + "px, " + w + "px)"
          : "translate3d(" + v + "px, " + w + "px, 0)"),
      D)
    );
  }
  return Object.assign(
    {},
    P,
    ((e = {}),
    (e[c] = y ? w + "px" : ""),
    (e[L] = h ? v + "px" : ""),
    (e.transform = ""),
    e)
  );
}
function si(t) {
  var e = t.state,
    r = t.options,
    i = r.gpuAcceleration,
    n = i === void 0 ? !0 : i,
    o = r.adaptive,
    a = o === void 0 ? !0 : o,
    s = r.roundOffsets,
    l = s === void 0 ? !0 : s,
    u = {
      placement: te(e.placement),
      variation: qe(e.placement),
      popper: e.elements.popper,
      popperRect: e.rects.popper,
      gpuAcceleration: n,
      isFixed: e.options.strategy === "fixed",
    };
  e.modifiersData.popperOffsets != null &&
    (e.styles.popper = Object.assign(
      {},
      e.styles.popper,
      Ft(
        Object.assign({}, u, {
          offsets: e.modifiersData.popperOffsets,
          position: e.options.strategy,
          adaptive: a,
          roundOffsets: l,
        })
      )
    )),
    e.modifiersData.arrow != null &&
      (e.styles.arrow = Object.assign(
        {},
        e.styles.arrow,
        Ft(
          Object.assign({}, u, {
            offsets: e.modifiersData.arrow,
            position: "absolute",
            adaptive: !1,
            roundOffsets: l,
          })
        )
      )),
    (e.attributes.popper = Object.assign({}, e.attributes.popper, {
      "data-popper-placement": e.placement,
    }));
}
const ai = {
  name: "computeStyles",
  enabled: !0,
  phase: "beforeWrite",
  fn: si,
  data: {},
};
var it = { passive: !0 };
function li(t) {
  var e = t.state,
    r = t.instance,
    i = t.options,
    n = i.scroll,
    o = n === void 0 ? !0 : n,
    a = i.resize,
    s = a === void 0 ? !0 : a,
    l = j(e.elements.popper),
    u = [].concat(e.scrollParents.reference, e.scrollParents.popper);
  return (
    o &&
      u.forEach(function (d) {
        d.addEventListener("scroll", r.update, it);
      }),
    s && l.addEventListener("resize", r.update, it),
    function () {
      o &&
        u.forEach(function (d) {
          d.removeEventListener("scroll", r.update, it);
        }),
        s && l.removeEventListener("resize", r.update, it);
    }
  );
}
const ci = {
  name: "eventListeners",
  enabled: !0,
  phase: "write",
  fn: function () {},
  effect: li,
  data: {},
};
var ui = { left: "right", right: "left", bottom: "top", top: "bottom" };
function st(t) {
  return t.replace(/left|right|bottom|top/g, function (e) {
    return ui[e];
  });
}
var di = { start: "end", end: "start" };
function jt(t) {
  return t.replace(/start|end/g, function (e) {
    return di[e];
  });
}
function Dt(t) {
  var e = j(t),
    r = e.pageXOffset,
    i = e.pageYOffset;
  return { scrollLeft: r, scrollTop: i };
}
function Pt(t) {
  return Oe(ue(t)).left + Dt(t).scrollLeft;
}
function fi(t, e) {
  var r = j(t),
    i = ue(t),
    n = r.visualViewport,
    o = i.clientWidth,
    a = i.clientHeight,
    s = 0,
    l = 0;
  if (n) {
    (o = n.width), (a = n.height);
    var u = cr();
    (u || (!u && e === "fixed")) && ((s = n.offsetLeft), (l = n.offsetTop));
  }
  return { width: o, height: a, x: s + Pt(t), y: l };
}
function pi(t) {
  var e,
    r = ue(t),
    i = Dt(t),
    n = (e = t.ownerDocument) == null ? void 0 : e.body,
    o = ge(
      r.scrollWidth,
      r.clientWidth,
      n ? n.scrollWidth : 0,
      n ? n.clientWidth : 0
    ),
    a = ge(
      r.scrollHeight,
      r.clientHeight,
      n ? n.scrollHeight : 0,
      n ? n.clientHeight : 0
    ),
    s = -i.scrollLeft + Pt(t),
    l = -i.scrollTop;
  return (
    ae(n || r).direction === "rtl" &&
      (s += ge(r.clientWidth, n ? n.clientWidth : 0) - o),
    { width: o, height: a, x: s, y: l }
  );
}
function Rt(t) {
  var e = ae(t),
    r = e.overflow,
    i = e.overflowX,
    n = e.overflowY;
  return /auto|scroll|overlay|hidden/.test(r + n + i);
}
function hr(t) {
  return ["html", "body", "#document"].indexOf(re(t)) >= 0
    ? t.ownerDocument.body
    : U(t) && Rt(t)
    ? t
    : hr(ut(t));
}
function Be(t, e) {
  var r;
  e === void 0 && (e = []);
  var i = hr(t),
    n = i === ((r = t.ownerDocument) == null ? void 0 : r.body),
    o = j(i),
    a = n ? [o].concat(o.visualViewport || [], Rt(i) ? i : []) : i,
    s = e.concat(a);
  return n ? s : s.concat(Be(ut(a)));
}
function _t(t) {
  return Object.assign({}, t, {
    left: t.x,
    top: t.y,
    right: t.x + t.width,
    bottom: t.y + t.height,
  });
}
function hi(t, e) {
  var r = Oe(t, !1, e === "fixed");
  return (
    (r.top = r.top + t.clientTop),
    (r.left = r.left + t.clientLeft),
    (r.bottom = r.top + t.clientHeight),
    (r.right = r.left + t.clientWidth),
    (r.width = t.clientWidth),
    (r.height = t.clientHeight),
    (r.x = r.left),
    (r.y = r.top),
    r
  );
}
function zt(t, e, r) {
  return e === sr ? _t(fi(t, r)) : ye(e) ? hi(e, r) : _t(pi(ue(t)));
}
function vi(t) {
  var e = Be(ut(t)),
    r = ["absolute", "fixed"].indexOf(ae(t).position) >= 0,
    i = r && U(t) ? ze(t) : t;
  return ye(i)
    ? e.filter(function (n) {
        return ye(n) && ur(n, i) && re(n) !== "body";
      })
    : [];
}
function mi(t, e, r, i) {
  var n = e === "clippingParents" ? vi(t) : [].concat(e),
    o = [].concat(n, [r]),
    a = o[0],
    s = o.reduce(function (l, u) {
      var d = zt(t, u, i);
      return (
        (l.top = ge(d.top, l.top)),
        (l.right = lt(d.right, l.right)),
        (l.bottom = lt(d.bottom, l.bottom)),
        (l.left = ge(d.left, l.left)),
        l
      );
    }, zt(t, a, i));
  return (
    (s.width = s.right - s.left),
    (s.height = s.bottom - s.top),
    (s.x = s.left),
    (s.y = s.top),
    s
  );
}
function vr(t) {
  var e = t.reference,
    r = t.element,
    i = t.placement,
    n = i ? te(i) : null,
    o = i ? qe(i) : null,
    a = e.x + e.width / 2 - r.width / 2,
    s = e.y + e.height / 2 - r.height / 2,
    l;
  switch (n) {
    case B:
      l = { x: a, y: e.y - r.height };
      break;
    case V:
      l = { x: a, y: e.y + e.height };
      break;
    case Y:
      l = { x: e.x + e.width, y: s };
      break;
    case N:
      l = { x: e.x - r.width, y: s };
      break;
    default:
      l = { x: e.x, y: e.y };
  }
  var u = n ? kt(n) : null;
  if (u != null) {
    var d = u === "y" ? "height" : "width";
    switch (o) {
      case Te:
        l[u] = l[u] - (e[d] / 2 - r[d] / 2);
        break;
      case We:
        l[u] = l[u] + (e[d] / 2 - r[d] / 2);
        break;
    }
  }
  return l;
}
function Fe(t, e) {
  e === void 0 && (e = {});
  var r = e,
    i = r.placement,
    n = i === void 0 ? t.placement : i,
    o = r.strategy,
    a = o === void 0 ? t.strategy : o,
    s = r.boundary,
    l = s === void 0 ? Ir : s,
    u = r.rootBoundary,
    d = u === void 0 ? sr : u,
    p = r.elementContext,
    g = p === void 0 ? He : p,
    v = r.altBoundary,
    E = v === void 0 ? !1 : v,
    w = r.padding,
    S = w === void 0 ? 0 : w,
    h = fr(typeof S != "number" ? S : pr(S, je)),
    y = g === He ? $r : He,
    L = t.rects.popper,
    c = t.elements[E ? y : g],
    _ = mi(ye(c) ? c : c.contextElement || ue(t.elements.popper), l, d, a),
    m = Oe(t.elements.reference),
    T = vr({ reference: m, element: L, strategy: "absolute", placement: n }),
    q = _t(Object.assign({}, L, T)),
    k = g === He ? q : m,
    C = {
      top: _.top - k.top + h.top,
      bottom: k.bottom - _.bottom + h.bottom,
      left: _.left - k.left + h.left,
      right: k.right - _.right + h.right,
    },
    P = t.modifiersData.offset;
  if (g === He && P) {
    var M = P[n];
    Object.keys(C).forEach(function (D) {
      var W = [Y, V].indexOf(D) >= 0 ? 1 : -1,
        F = [B, V].indexOf(D) >= 0 ? "y" : "x";
      C[D] += M[F] * W;
    });
  }
  return C;
}
function gi(t, e) {
  e === void 0 && (e = {});
  var r = e,
    i = r.placement,
    n = r.boundary,
    o = r.rootBoundary,
    a = r.padding,
    s = r.flipVariations,
    l = r.allowedAutoPlacements,
    u = l === void 0 ? ar : l,
    d = qe(i),
    p = d
      ? s
        ? Nt
        : Nt.filter(function (E) {
            return qe(E) === d;
          })
      : je,
    g = p.filter(function (E) {
      return u.indexOf(E) >= 0;
    });
  g.length === 0 && (g = p);
  var v = g.reduce(function (E, w) {
    return (
      (E[w] = Fe(t, { placement: w, boundary: n, rootBoundary: o, padding: a })[
        te(w)
      ]),
      E
    );
  }, {});
  return Object.keys(v).sort(function (E, w) {
    return v[E] - v[w];
  });
}
function yi(t) {
  if (te(t) === qt) return [];
  var e = st(t);
  return [jt(t), e, jt(e)];
}
function wi(t) {
  var e = t.state,
    r = t.options,
    i = t.name;
  if (!e.modifiersData[i]._skip) {
    for (
      var n = r.mainAxis,
        o = n === void 0 ? !0 : n,
        a = r.altAxis,
        s = a === void 0 ? !0 : a,
        l = r.fallbackPlacements,
        u = r.padding,
        d = r.boundary,
        p = r.rootBoundary,
        g = r.altBoundary,
        v = r.flipVariations,
        E = v === void 0 ? !0 : v,
        w = r.allowedAutoPlacements,
        S = e.options.placement,
        h = te(S),
        y = h === S,
        L = l || (y || !E ? [st(S)] : yi(S)),
        c = [S].concat(L).reduce(function (ie, X) {
          return ie.concat(
            te(X) === qt
              ? gi(e, {
                  placement: X,
                  boundary: d,
                  rootBoundary: p,
                  padding: u,
                  flipVariations: E,
                  allowedAutoPlacements: w,
                })
              : X
          );
        }, []),
        _ = e.rects.reference,
        m = e.rects.popper,
        T = new Map(),
        q = !0,
        k = c[0],
        C = 0;
      C < c.length;
      C++
    ) {
      var P = c[C],
        M = te(P),
        D = qe(P) === Te,
        W = [B, V].indexOf(M) >= 0,
        F = W ? "width" : "height",
        H = Fe(e, {
          placement: P,
          boundary: d,
          rootBoundary: p,
          altBoundary: g,
          padding: u,
        }),
        I = W ? (D ? Y : N) : D ? V : B;
      _[F] > m[F] && (I = st(I));
      var R = st(I),
        K = [];
      if (
        (o && K.push(H[M] <= 0),
        s && K.push(H[I] <= 0, H[R] <= 0),
        K.every(function (ie) {
          return ie;
        }))
      ) {
        (k = P), (q = !1);
        break;
      }
      T.set(P, K);
    }
    if (q)
      for (
        var J = E ? 3 : 1,
          de = function (X) {
            var ne = c.find(function (we) {
              var oe = T.get(we);
              if (oe)
                return oe.slice(0, X).every(function (be) {
                  return be;
                });
            });
            if (ne) return (k = ne), "break";
          },
          Q = J;
        Q > 0;
        Q--
      ) {
        var fe = de(Q);
        if (fe === "break") break;
      }
    e.placement !== k &&
      ((e.modifiersData[i]._skip = !0), (e.placement = k), (e.reset = !0));
  }
}
const bi = {
  name: "flip",
  enabled: !0,
  phase: "main",
  fn: wi,
  requiresIfExists: ["offset"],
  data: { _skip: !1 },
};
function Ut(t, e, r) {
  return (
    r === void 0 && (r = { x: 0, y: 0 }),
    {
      top: t.top - e.height - r.y,
      right: t.right - e.width + r.x,
      bottom: t.bottom - e.height + r.y,
      left: t.left - e.width - r.x,
    }
  );
}
function Vt(t) {
  return [B, Y, V, N].some(function (e) {
    return t[e] >= 0;
  });
}
function Si(t) {
  var e = t.state,
    r = t.name,
    i = e.rects.reference,
    n = e.rects.popper,
    o = e.modifiersData.preventOverflow,
    a = Fe(e, { elementContext: "reference" }),
    s = Fe(e, { altBoundary: !0 }),
    l = Ut(a, i),
    u = Ut(s, n, o),
    d = Vt(l),
    p = Vt(u);
  (e.modifiersData[r] = {
    referenceClippingOffsets: l,
    popperEscapeOffsets: u,
    isReferenceHidden: d,
    hasPopperEscaped: p,
  }),
    (e.attributes.popper = Object.assign({}, e.attributes.popper, {
      "data-popper-reference-hidden": d,
      "data-popper-escaped": p,
    }));
}
const Li = {
  name: "hide",
  enabled: !0,
  phase: "main",
  requiresIfExists: ["preventOverflow"],
  fn: Si,
};
function Ei(t, e, r) {
  var i = te(t),
    n = [N, B].indexOf(i) >= 0 ? -1 : 1,
    o = typeof r == "function" ? r(Object.assign({}, e, { placement: t })) : r,
    a = o[0],
    s = o[1];
  return (
    (a = a || 0),
    (s = (s || 0) * n),
    [N, Y].indexOf(i) >= 0 ? { x: s, y: a } : { x: a, y: s }
  );
}
function Ai(t) {
  var e = t.state,
    r = t.options,
    i = t.name,
    n = r.offset,
    o = n === void 0 ? [0, 0] : n,
    a = ar.reduce(function (d, p) {
      return (d[p] = Ei(p, e.rects, o)), d;
    }, {}),
    s = a[e.placement],
    l = s.x,
    u = s.y;
  e.modifiersData.popperOffsets != null &&
    ((e.modifiersData.popperOffsets.x += l),
    (e.modifiersData.popperOffsets.y += u)),
    (e.modifiersData[i] = a);
}
const _i = {
  name: "offset",
  enabled: !0,
  phase: "main",
  requires: ["popperOffsets"],
  fn: Ai,
};
function Ti(t) {
  var e = t.state,
    r = t.name;
  e.modifiersData[r] = vr({
    reference: e.rects.reference,
    element: e.rects.popper,
    strategy: "absolute",
    placement: e.placement,
  });
}
const xi = {
  name: "popperOffsets",
  enabled: !0,
  phase: "read",
  fn: Ti,
  data: {},
};
function Oi(t) {
  return t === "x" ? "y" : "x";
}
function qi(t) {
  var e = t.state,
    r = t.options,
    i = t.name,
    n = r.mainAxis,
    o = n === void 0 ? !0 : n,
    a = r.altAxis,
    s = a === void 0 ? !1 : a,
    l = r.boundary,
    u = r.rootBoundary,
    d = r.altBoundary,
    p = r.padding,
    g = r.tether,
    v = g === void 0 ? !0 : g,
    E = r.tetherOffset,
    w = E === void 0 ? 0 : E,
    S = Fe(e, { boundary: l, rootBoundary: u, padding: p, altBoundary: d }),
    h = te(e.placement),
    y = qe(e.placement),
    L = !y,
    c = kt(h),
    _ = Oi(c),
    m = e.modifiersData.popperOffsets,
    T = e.rects.reference,
    q = e.rects.popper,
    k =
      typeof w == "function"
        ? w(Object.assign({}, e.rects, { placement: e.placement }))
        : w,
    C =
      typeof k == "number"
        ? { mainAxis: k, altAxis: k }
        : Object.assign({ mainAxis: 0, altAxis: 0 }, k),
    P = e.modifiersData.offset ? e.modifiersData.offset[e.placement] : null,
    M = { x: 0, y: 0 };
  if (m) {
    if (o) {
      var D,
        W = c === "y" ? B : N,
        F = c === "y" ? V : Y,
        H = c === "y" ? "height" : "width",
        I = m[c],
        R = I + S[W],
        K = I - S[F],
        J = v ? -q[H] / 2 : 0,
        de = y === Te ? T[H] : q[H],
        Q = y === Te ? -q[H] : -T[H],
        fe = e.elements.arrow,
        ie = v && fe ? Mt(fe) : { width: 0, height: 0 },
        X = e.modifiersData["arrow#persistent"]
          ? e.modifiersData["arrow#persistent"].padding
          : dr(),
        ne = X[W],
        we = X[F],
        oe = $e(0, T[H], ie[H]),
        be = L
          ? T[H] / 2 - J - oe - ne - C.mainAxis
          : de - oe - ne - C.mainAxis,
        le = L
          ? -T[H] / 2 + J + oe + we + C.mainAxis
          : Q + oe + we + C.mainAxis,
        Se = e.elements.arrow && ze(e.elements.arrow),
        Ue = Se ? (c === "y" ? Se.clientTop || 0 : Se.clientLeft || 0) : 0,
        Me = (D = P == null ? void 0 : P[c]) != null ? D : 0,
        Ve = I + be - Me - Ue,
        Ye = I + le - Me,
        ke = $e(v ? lt(R, Ve) : R, I, v ? ge(K, Ye) : K);
      (m[c] = ke), (M[c] = ke - I);
    }
    if (s) {
      var De,
        Xe = c === "x" ? B : N,
        Ge = c === "x" ? V : Y,
        se = m[_],
        ce = _ === "y" ? "height" : "width",
        Pe = se + S[Xe],
        pe = se - S[Ge],
        Re = [B, N].indexOf(h) !== -1,
        Ke = (De = P == null ? void 0 : P[_]) != null ? De : 0,
        Je = Re ? Pe : se - T[ce] - q[ce] - Ke + C.altAxis,
        Qe = Re ? se + T[ce] + q[ce] - Ke - C.altAxis : pe,
        Ze = v && Re ? Zr(Je, se, Qe) : $e(v ? Je : Pe, se, v ? Qe : pe);
      (m[_] = Ze), (M[_] = Ze - se);
    }
    e.modifiersData[i] = M;
  }
}
const Ci = {
  name: "preventOverflow",
  enabled: !0,
  phase: "main",
  fn: qi,
  requiresIfExists: ["offset"],
};
function Mi(t) {
  return { scrollLeft: t.scrollLeft, scrollTop: t.scrollTop };
}
function ki(t) {
  return t === j(t) || !U(t) ? Dt(t) : Mi(t);
}
function Di(t) {
  var e = t.getBoundingClientRect(),
    r = xe(e.width) / t.offsetWidth || 1,
    i = xe(e.height) / t.offsetHeight || 1;
  return r !== 1 || i !== 1;
}
function Pi(t, e, r) {
  r === void 0 && (r = !1);
  var i = U(e),
    n = U(e) && Di(e),
    o = ue(e),
    a = Oe(t, n, r),
    s = { scrollLeft: 0, scrollTop: 0 },
    l = { x: 0, y: 0 };
  return (
    (i || (!i && !r)) &&
      ((re(e) !== "body" || Rt(o)) && (s = ki(e)),
      U(e)
        ? ((l = Oe(e, !0)), (l.x += e.clientLeft), (l.y += e.clientTop))
        : o && (l.x = Pt(o))),
    {
      x: a.left + s.scrollLeft - l.x,
      y: a.top + s.scrollTop - l.y,
      width: a.width,
      height: a.height,
    }
  );
}
function Ri(t) {
  var e = new Map(),
    r = new Set(),
    i = [];
  t.forEach(function (o) {
    e.set(o.name, o);
  });
  function n(o) {
    r.add(o.name);
    var a = [].concat(o.requires || [], o.requiresIfExists || []);
    a.forEach(function (s) {
      if (!r.has(s)) {
        var l = e.get(s);
        l && n(l);
      }
    }),
      i.push(o);
  }
  return (
    t.forEach(function (o) {
      r.has(o.name) || n(o);
    }),
    i
  );
}
function Hi(t) {
  var e = Ri(t);
  return Xr.reduce(function (r, i) {
    return r.concat(
      e.filter(function (n) {
        return n.phase === i;
      })
    );
  }, []);
}
function Ii(t) {
  var e;
  return function () {
    return (
      e ||
        (e = new Promise(function (r) {
          Promise.resolve().then(function () {
            (e = void 0), r(t());
          });
        })),
      e
    );
  };
}
function $i(t) {
  var e = t.reduce(function (r, i) {
    var n = r[i.name];
    return (
      (r[i.name] = n
        ? Object.assign({}, n, i, {
            options: Object.assign({}, n.options, i.options),
            data: Object.assign({}, n.data, i.data),
          })
        : i),
      r
    );
  }, {});
  return Object.keys(e).map(function (r) {
    return e[r];
  });
}
var Yt = { placement: "bottom", modifiers: [], strategy: "absolute" };
function Xt() {
  for (var t = arguments.length, e = new Array(t), r = 0; r < t; r++)
    e[r] = arguments[r];
  return !e.some(function (i) {
    return !(i && typeof i.getBoundingClientRect == "function");
  });
}
function Bi(t) {
  t === void 0 && (t = {});
  var e = t,
    r = e.defaultModifiers,
    i = r === void 0 ? [] : r,
    n = e.defaultOptions,
    o = n === void 0 ? Yt : n;
  return function (s, l, u) {
    u === void 0 && (u = o);
    var d = {
        placement: "bottom",
        orderedModifiers: [],
        options: Object.assign({}, Yt, o),
        modifiersData: {},
        elements: { reference: s, popper: l },
        attributes: {},
        styles: {},
      },
      p = [],
      g = !1,
      v = {
        state: d,
        setOptions: function (h) {
          var y = typeof h == "function" ? h(d.options) : h;
          w(),
            (d.options = Object.assign({}, o, d.options, y)),
            (d.scrollParents = {
              reference: ye(s)
                ? Be(s)
                : s.contextElement
                ? Be(s.contextElement)
                : [],
              popper: Be(l),
            });
          var L = Hi($i([].concat(i, d.options.modifiers)));
          return (
            (d.orderedModifiers = L.filter(function (c) {
              return c.enabled;
            })),
            E(),
            v.update()
          );
        },
        forceUpdate: function () {
          if (!g) {
            var h = d.elements,
              y = h.reference,
              L = h.popper;
            if (Xt(y, L)) {
              (d.rects = {
                reference: Pi(y, ze(L), d.options.strategy === "fixed"),
                popper: Mt(L),
              }),
                (d.reset = !1),
                (d.placement = d.options.placement),
                d.orderedModifiers.forEach(function (C) {
                  return (d.modifiersData[C.name] = Object.assign({}, C.data));
                });
              for (var c = 0; c < d.orderedModifiers.length; c++) {
                if (d.reset === !0) {
                  (d.reset = !1), (c = -1);
                  continue;
                }
                var _ = d.orderedModifiers[c],
                  m = _.fn,
                  T = _.options,
                  q = T === void 0 ? {} : T,
                  k = _.name;
                typeof m == "function" &&
                  (d = m({ state: d, options: q, name: k, instance: v }) || d);
              }
            }
          }
        },
        update: Ii(function () {
          return new Promise(function (S) {
            v.forceUpdate(), S(d);
          });
        }),
        destroy: function () {
          w(), (g = !0);
        },
      };
    if (!Xt(s, l)) return v;
    v.setOptions(u).then(function (S) {
      !g && u.onFirstUpdate && u.onFirstUpdate(S);
    });
    function E() {
      d.orderedModifiers.forEach(function (S) {
        var h = S.name,
          y = S.options,
          L = y === void 0 ? {} : y,
          c = S.effect;
        if (typeof c == "function") {
          var _ = c({ state: d, name: h, instance: v, options: L }),
            m = function () {};
          p.push(_ || m);
        }
      });
    }
    function w() {
      p.forEach(function (S) {
        return S();
      }),
        (p = []);
    }
    return v;
  };
}
var Ni = [ci, xi, ai, lr, _i, bi, Ci, ii, Li],
  Wi = Bi({ defaultModifiers: Ni }),
  Fi = "tippy-box",
  mr = "tippy-content",
  ji = "tippy-backdrop",
  gr = "tippy-arrow",
  yr = "tippy-svg-arrow",
  me = { passive: !0, capture: !0 },
  wr = function () {
    return document.body;
  };
function gt(t, e, r) {
  if (Array.isArray(t)) {
    var i = t[e];
    return i ?? (Array.isArray(r) ? r[e] : r);
  }
  return t;
}
function Ht(t, e) {
  var r = {}.toString.call(t);
  return r.indexOf("[object") === 0 && r.indexOf(e + "]") > -1;
}
function br(t, e) {
  return typeof t == "function" ? t.apply(void 0, e) : t;
}
function Gt(t, e) {
  if (e === 0) return t;
  var r;
  return function (i) {
    clearTimeout(r),
      (r = setTimeout(function () {
        t(i);
      }, e));
  };
}
function zi(t) {
  return t.split(/\s+/).filter(Boolean);
}
function Ae(t) {
  return [].concat(t);
}
function Kt(t, e) {
  t.indexOf(e) === -1 && t.push(e);
}
function Ui(t) {
  return t.filter(function (e, r) {
    return t.indexOf(e) === r;
  });
}
function Vi(t) {
  return t.split("-")[0];
}
function ct(t) {
  return [].slice.call(t);
}
function Jt(t) {
  return Object.keys(t).reduce(function (e, r) {
    return t[r] !== void 0 && (e[r] = t[r]), e;
  }, {});
}
function Ne() {
  return document.createElement("div");
}
function dt(t) {
  return ["Element", "Fragment"].some(function (e) {
    return Ht(t, e);
  });
}
function Yi(t) {
  return Ht(t, "NodeList");
}
function Xi(t) {
  return Ht(t, "MouseEvent");
}
function Gi(t) {
  return !!(t && t._tippy && t._tippy.reference === t);
}
function Ki(t) {
  return dt(t)
    ? [t]
    : Yi(t)
    ? ct(t)
    : Array.isArray(t)
    ? t
    : ct(document.querySelectorAll(t));
}
function yt(t, e) {
  t.forEach(function (r) {
    r && (r.style.transitionDuration = e + "ms");
  });
}
function Qt(t, e) {
  t.forEach(function (r) {
    r && r.setAttribute("data-state", e);
  });
}
function Ji(t) {
  var e,
    r = Ae(t),
    i = r[0];
  return i != null && (e = i.ownerDocument) != null && e.body
    ? i.ownerDocument
    : document;
}
function Qi(t, e) {
  var r = e.clientX,
    i = e.clientY;
  return t.every(function (n) {
    var o = n.popperRect,
      a = n.popperState,
      s = n.props,
      l = s.interactiveBorder,
      u = Vi(a.placement),
      d = a.modifiersData.offset;
    if (!d) return !0;
    var p = u === "bottom" ? d.top.y : 0,
      g = u === "top" ? d.bottom.y : 0,
      v = u === "right" ? d.left.x : 0,
      E = u === "left" ? d.right.x : 0,
      w = o.top - i + p > l,
      S = i - o.bottom - g > l,
      h = o.left - r + v > l,
      y = r - o.right - E > l;
    return w || S || h || y;
  });
}
function wt(t, e, r) {
  var i = e + "EventListener";
  ["transitionend", "webkitTransitionEnd"].forEach(function (n) {
    t[i](n, r);
  });
}
function Zt(t, e) {
  for (var r = e; r; ) {
    var i;
    if (t.contains(r)) return !0;
    r =
      r.getRootNode == null || (i = r.getRootNode()) == null ? void 0 : i.host;
  }
  return !1;
}
var ee = { isTouch: !1 },
  er = 0;
function Zi() {
  ee.isTouch ||
    ((ee.isTouch = !0),
    window.performance && document.addEventListener("mousemove", Sr));
}
function Sr() {
  var t = performance.now();
  t - er < 20 &&
    ((ee.isTouch = !1), document.removeEventListener("mousemove", Sr)),
    (er = t);
}
function en() {
  var t = document.activeElement;
  if (Gi(t)) {
    var e = t._tippy;
    t.blur && !e.state.isVisible && t.blur();
  }
}
function tn() {
  document.addEventListener("touchstart", Zi, me),
    window.addEventListener("blur", en);
}
var rn = typeof window < "u" && typeof document < "u",
  nn = rn ? !!window.msCrypto : !1,
  on = { animateFill: !1, followCursor: !1, inlinePositioning: !1, sticky: !1 },
  sn = {
    allowHTML: !1,
    animation: "fade",
    arrow: !0,
    content: "",
    inertia: !1,
    maxWidth: 350,
    role: "tooltip",
    theme: "",
    zIndex: 9999,
  },
  G = Object.assign(
    {
      appendTo: wr,
      aria: { content: "auto", expanded: "auto" },
      delay: 0,
      duration: [300, 250],
      getReferenceClientRect: null,
      hideOnClick: !0,
      ignoreAttributes: !1,
      interactive: !1,
      interactiveBorder: 2,
      interactiveDebounce: 0,
      moveTransition: "",
      offset: [0, 10],
      onAfterUpdate: function () {},
      onBeforeUpdate: function () {},
      onCreate: function () {},
      onDestroy: function () {},
      onHidden: function () {},
      onHide: function () {},
      onMount: function () {},
      onShow: function () {},
      onShown: function () {},
      onTrigger: function () {},
      onUntrigger: function () {},
      onClickOutside: function () {},
      placement: "top",
      plugins: [],
      popperOptions: {},
      render: null,
      showOnCreate: !1,
      touch: !0,
      trigger: "mouseenter focus",
      triggerTarget: null,
    },
    on,
    sn
  ),
  an = Object.keys(G),
  ln = function (e) {
    var r = Object.keys(e);
    r.forEach(function (i) {
      G[i] = e[i];
    });
  };
function Lr(t) {
  var e = t.plugins || [],
    r = e.reduce(function (i, n) {
      var o = n.name,
        a = n.defaultValue;
      if (o) {
        var s;
        i[o] = t[o] !== void 0 ? t[o] : (s = G[o]) != null ? s : a;
      }
      return i;
    }, {});
  return Object.assign({}, t, r);
}
function cn(t, e) {
  var r = e ? Object.keys(Lr(Object.assign({}, G, { plugins: e }))) : an,
    i = r.reduce(function (n, o) {
      var a = (t.getAttribute("data-tippy-" + o) || "").trim();
      if (!a) return n;
      if (o === "content") n[o] = a;
      else
        try {
          n[o] = JSON.parse(a);
        } catch {
          n[o] = a;
        }
      return n;
    }, {});
  return i;
}
function tr(t, e) {
  var r = Object.assign(
    {},
    e,
    { content: br(e.content, [t]) },
    e.ignoreAttributes ? {} : cn(t, e.plugins)
  );
  return (
    (r.aria = Object.assign({}, G.aria, r.aria)),
    (r.aria = {
      expanded: r.aria.expanded === "auto" ? e.interactive : r.aria.expanded,
      content:
        r.aria.content === "auto"
          ? e.interactive
            ? null
            : "describedby"
          : r.aria.content,
    }),
    r
  );
}
var un = function () {
  return "innerHTML";
};
function Tt(t, e) {
  t[un()] = e;
}
function rr(t) {
  var e = Ne();
  return (
    t === !0
      ? (e.className = gr)
      : ((e.className = yr), dt(t) ? e.appendChild(t) : Tt(e, t)),
    e
  );
}
function ir(t, e) {
  dt(e.content)
    ? (Tt(t, ""), t.appendChild(e.content))
    : typeof e.content != "function" &&
      (e.allowHTML ? Tt(t, e.content) : (t.textContent = e.content));
}
function xt(t) {
  var e = t.firstElementChild,
    r = ct(e.children);
  return {
    box: e,
    content: r.find(function (i) {
      return i.classList.contains(mr);
    }),
    arrow: r.find(function (i) {
      return i.classList.contains(gr) || i.classList.contains(yr);
    }),
    backdrop: r.find(function (i) {
      return i.classList.contains(ji);
    }),
  };
}
function Er(t) {
  var e = Ne(),
    r = Ne();
  (r.className = Fi),
    r.setAttribute("data-state", "hidden"),
    r.setAttribute("tabindex", "-1");
  var i = Ne();
  (i.className = mr),
    i.setAttribute("data-state", "hidden"),
    ir(i, t.props),
    e.appendChild(r),
    r.appendChild(i),
    n(t.props, t.props);
  function n(o, a) {
    var s = xt(e),
      l = s.box,
      u = s.content,
      d = s.arrow;
    a.theme
      ? l.setAttribute("data-theme", a.theme)
      : l.removeAttribute("data-theme"),
      typeof a.animation == "string"
        ? l.setAttribute("data-animation", a.animation)
        : l.removeAttribute("data-animation"),
      a.inertia
        ? l.setAttribute("data-inertia", "")
        : l.removeAttribute("data-inertia"),
      (l.style.maxWidth =
        typeof a.maxWidth == "number" ? a.maxWidth + "px" : a.maxWidth),
      a.role ? l.setAttribute("role", a.role) : l.removeAttribute("role"),
      (o.content !== a.content || o.allowHTML !== a.allowHTML) &&
        ir(u, t.props),
      a.arrow
        ? d
          ? o.arrow !== a.arrow &&
            (l.removeChild(d), l.appendChild(rr(a.arrow)))
          : l.appendChild(rr(a.arrow))
        : d && l.removeChild(d);
  }
  return { popper: e, onUpdate: n };
}
Er.$$tippy = !0;
var dn = 1,
  nt = [],
  bt = [];
function fn(t, e) {
  var r = tr(t, Object.assign({}, G, Lr(Jt(e)))),
    i,
    n,
    o,
    a = !1,
    s = !1,
    l = !1,
    u = !1,
    d,
    p,
    g,
    v = [],
    E = Gt(Ve, r.interactiveDebounce),
    w,
    S = dn++,
    h = null,
    y = Ui(r.plugins),
    L = {
      isEnabled: !0,
      isVisible: !1,
      isDestroyed: !1,
      isMounted: !1,
      isShown: !1,
    },
    c = {
      id: S,
      reference: t,
      popper: Ne(),
      popperInstance: h,
      props: r,
      state: L,
      plugins: y,
      clearDelayTimeouts: Je,
      setProps: Qe,
      setContent: Ze,
      show: Ar,
      hide: _r,
      hideWithInteractivity: Tr,
      enable: Re,
      disable: Ke,
      unmount: xr,
      destroy: Or,
    };
  if (!r.render) return c;
  var _ = r.render(c),
    m = _.popper,
    T = _.onUpdate;
  m.setAttribute("data-tippy-root", ""),
    (m.id = "tippy-" + c.id),
    (c.popper = m),
    (t._tippy = c),
    (m._tippy = c);
  var q = y.map(function (f) {
      return f.fn(c);
    }),
    k = t.hasAttribute("aria-expanded");
  return (
    Se(),
    J(),
    I(),
    R("onCreate", [c]),
    r.showOnCreate && Pe(),
    m.addEventListener("mouseenter", function () {
      c.props.interactive && c.state.isVisible && c.clearDelayTimeouts();
    }),
    m.addEventListener("mouseleave", function () {
      c.props.interactive &&
        c.props.trigger.indexOf("mouseenter") >= 0 &&
        W().addEventListener("mousemove", E);
    }),
    c
  );
  function C() {
    var f = c.props.touch;
    return Array.isArray(f) ? f : [f, 0];
  }
  function P() {
    return C()[0] === "hold";
  }
  function M() {
    var f;
    return !!((f = c.props.render) != null && f.$$tippy);
  }
  function D() {
    return w || t;
  }
  function W() {
    var f = D().parentNode;
    return f ? Ji(f) : document;
  }
  function F() {
    return xt(m);
  }
  function H(f) {
    return (c.state.isMounted && !c.state.isVisible) ||
      ee.isTouch ||
      (d && d.type === "focus")
      ? 0
      : gt(c.props.delay, f ? 0 : 1, G.delay);
  }
  function I(f) {
    f === void 0 && (f = !1),
      (m.style.pointerEvents = c.props.interactive && !f ? "" : "none"),
      (m.style.zIndex = "" + c.props.zIndex);
  }
  function R(f, b, A) {
    if (
      (A === void 0 && (A = !0),
      q.forEach(function (x) {
        x[f] && x[f].apply(x, b);
      }),
      A)
    ) {
      var O;
      (O = c.props)[f].apply(O, b);
    }
  }
  function K() {
    var f = c.props.aria;
    if (f.content) {
      var b = "aria-" + f.content,
        A = m.id,
        O = Ae(c.props.triggerTarget || t);
      O.forEach(function (x) {
        var $ = x.getAttribute(b);
        if (c.state.isVisible) x.setAttribute(b, $ ? $ + " " + A : A);
        else {
          var z = $ && $.replace(A, "").trim();
          z ? x.setAttribute(b, z) : x.removeAttribute(b);
        }
      });
    }
  }
  function J() {
    if (!(k || !c.props.aria.expanded)) {
      var f = Ae(c.props.triggerTarget || t);
      f.forEach(function (b) {
        c.props.interactive
          ? b.setAttribute(
              "aria-expanded",
              c.state.isVisible && b === D() ? "true" : "false"
            )
          : b.removeAttribute("aria-expanded");
      });
    }
  }
  function de() {
    W().removeEventListener("mousemove", E),
      (nt = nt.filter(function (f) {
        return f !== E;
      }));
  }
  function Q(f) {
    if (!(ee.isTouch && (l || f.type === "mousedown"))) {
      var b = (f.composedPath && f.composedPath()[0]) || f.target;
      if (!(c.props.interactive && Zt(m, b))) {
        if (
          Ae(c.props.triggerTarget || t).some(function (A) {
            return Zt(A, b);
          })
        ) {
          if (
            ee.isTouch ||
            (c.state.isVisible && c.props.trigger.indexOf("click") >= 0)
          )
            return;
        } else R("onClickOutside", [c, f]);
        c.props.hideOnClick === !0 &&
          (c.clearDelayTimeouts(),
          c.hide(),
          (s = !0),
          setTimeout(function () {
            s = !1;
          }),
          c.state.isMounted || ne());
      }
    }
  }
  function fe() {
    l = !0;
  }
  function ie() {
    l = !1;
  }
  function X() {
    var f = W();
    f.addEventListener("mousedown", Q, !0),
      f.addEventListener("touchend", Q, me),
      f.addEventListener("touchstart", ie, me),
      f.addEventListener("touchmove", fe, me);
  }
  function ne() {
    var f = W();
    f.removeEventListener("mousedown", Q, !0),
      f.removeEventListener("touchend", Q, me),
      f.removeEventListener("touchstart", ie, me),
      f.removeEventListener("touchmove", fe, me);
  }
  function we(f, b) {
    be(f, function () {
      !c.state.isVisible && m.parentNode && m.parentNode.contains(m) && b();
    });
  }
  function oe(f, b) {
    be(f, b);
  }
  function be(f, b) {
    var A = F().box;
    function O(x) {
      x.target === A && (wt(A, "remove", O), b());
    }
    if (f === 0) return b();
    wt(A, "remove", p), wt(A, "add", O), (p = O);
  }
  function le(f, b, A) {
    A === void 0 && (A = !1);
    var O = Ae(c.props.triggerTarget || t);
    O.forEach(function (x) {
      x.addEventListener(f, b, A),
        v.push({ node: x, eventType: f, handler: b, options: A });
    });
  }
  function Se() {
    P() &&
      (le("touchstart", Me, { passive: !0 }),
      le("touchend", Ye, { passive: !0 })),
      zi(c.props.trigger).forEach(function (f) {
        if (f !== "manual")
          switch ((le(f, Me), f)) {
            case "mouseenter":
              le("mouseleave", Ye);
              break;
            case "focus":
              le(nn ? "focusout" : "blur", ke);
              break;
            case "focusin":
              le("focusout", ke);
              break;
          }
      });
  }
  function Ue() {
    v.forEach(function (f) {
      var b = f.node,
        A = f.eventType,
        O = f.handler,
        x = f.options;
      b.removeEventListener(A, O, x);
    }),
      (v = []);
  }
  function Me(f) {
    var b,
      A = !1;
    if (!(!c.state.isEnabled || De(f) || s)) {
      var O = ((b = d) == null ? void 0 : b.type) === "focus";
      (d = f),
        (w = f.currentTarget),
        J(),
        !c.state.isVisible &&
          Xi(f) &&
          nt.forEach(function (x) {
            return x(f);
          }),
        f.type === "click" &&
        (c.props.trigger.indexOf("mouseenter") < 0 || a) &&
        c.props.hideOnClick !== !1 &&
        c.state.isVisible
          ? (A = !0)
          : Pe(f),
        f.type === "click" && (a = !A),
        A && !O && pe(f);
    }
  }
  function Ve(f) {
    var b = f.target,
      A = D().contains(b) || m.contains(b);
    if (!(f.type === "mousemove" && A)) {
      var O = ce()
        .concat(m)
        .map(function (x) {
          var $,
            z = x._tippy,
            Le = ($ = z.popperInstance) == null ? void 0 : $.state;
          return Le
            ? {
                popperRect: x.getBoundingClientRect(),
                popperState: Le,
                props: r,
              }
            : null;
        })
        .filter(Boolean);
      Qi(O, f) && (de(), pe(f));
    }
  }
  function Ye(f) {
    var b = De(f) || (c.props.trigger.indexOf("click") >= 0 && a);
    if (!b) {
      if (c.props.interactive) {
        c.hideWithInteractivity(f);
        return;
      }
      pe(f);
    }
  }
  function ke(f) {
    (c.props.trigger.indexOf("focusin") < 0 && f.target !== D()) ||
      (c.props.interactive && f.relatedTarget && m.contains(f.relatedTarget)) ||
      pe(f);
  }
  function De(f) {
    return ee.isTouch ? P() !== f.type.indexOf("touch") >= 0 : !1;
  }
  function Xe() {
    Ge();
    var f = c.props,
      b = f.popperOptions,
      A = f.placement,
      O = f.offset,
      x = f.getReferenceClientRect,
      $ = f.moveTransition,
      z = M() ? xt(m).arrow : null,
      Le = x
        ? { getBoundingClientRect: x, contextElement: x.contextElement || D() }
        : t,
      It = {
        name: "$$tippy",
        enabled: !0,
        phase: "beforeWrite",
        requires: ["computeStyles"],
        fn: function (et) {
          var Ee = et.state;
          if (M()) {
            var qr = F(),
              pt = qr.box;
            ["placement", "reference-hidden", "escaped"].forEach(function (tt) {
              tt === "placement"
                ? pt.setAttribute("data-placement", Ee.placement)
                : Ee.attributes.popper["data-popper-" + tt]
                ? pt.setAttribute("data-" + tt, "")
                : pt.removeAttribute("data-" + tt);
            }),
              (Ee.attributes.popper = {});
          }
        },
      },
      he = [
        { name: "offset", options: { offset: O } },
        {
          name: "preventOverflow",
          options: { padding: { top: 2, bottom: 2, left: 5, right: 5 } },
        },
        { name: "flip", options: { padding: 5 } },
        { name: "computeStyles", options: { adaptive: !$ } },
        It,
      ];
    M() && z && he.push({ name: "arrow", options: { element: z, padding: 3 } }),
      he.push.apply(he, (b == null ? void 0 : b.modifiers) || []),
      (c.popperInstance = Wi(
        Le,
        m,
        Object.assign({}, b, { placement: A, onFirstUpdate: g, modifiers: he })
      ));
  }
  function Ge() {
    c.popperInstance && (c.popperInstance.destroy(), (c.popperInstance = null));
  }
  function se() {
    var f = c.props.appendTo,
      b,
      A = D();
    (c.props.interactive && f === wr) || f === "parent"
      ? (b = A.parentNode)
      : (b = br(f, [A])),
      b.contains(m) || b.appendChild(m),
      (c.state.isMounted = !0),
      Xe();
  }
  function ce() {
    return ct(m.querySelectorAll("[data-tippy-root]"));
  }
  function Pe(f) {
    c.clearDelayTimeouts(), f && R("onTrigger", [c, f]), X();
    var b = H(!0),
      A = C(),
      O = A[0],
      x = A[1];
    ee.isTouch && O === "hold" && x && (b = x),
      b
        ? (i = setTimeout(function () {
            c.show();
          }, b))
        : c.show();
  }
  function pe(f) {
    if (
      (c.clearDelayTimeouts(), R("onUntrigger", [c, f]), !c.state.isVisible)
    ) {
      ne();
      return;
    }
    if (
      !(
        c.props.trigger.indexOf("mouseenter") >= 0 &&
        c.props.trigger.indexOf("click") >= 0 &&
        ["mouseleave", "mousemove"].indexOf(f.type) >= 0 &&
        a
      )
    ) {
      var b = H(!1);
      b
        ? (n = setTimeout(function () {
            c.state.isVisible && c.hide();
          }, b))
        : (o = requestAnimationFrame(function () {
            c.hide();
          }));
    }
  }
  function Re() {
    c.state.isEnabled = !0;
  }
  function Ke() {
    c.hide(), (c.state.isEnabled = !1);
  }
  function Je() {
    clearTimeout(i), clearTimeout(n), cancelAnimationFrame(o);
  }
  function Qe(f) {
    if (!c.state.isDestroyed) {
      R("onBeforeUpdate", [c, f]), Ue();
      var b = c.props,
        A = tr(t, Object.assign({}, b, Jt(f), { ignoreAttributes: !0 }));
      (c.props = A),
        Se(),
        b.interactiveDebounce !== A.interactiveDebounce &&
          (de(), (E = Gt(Ve, A.interactiveDebounce))),
        b.triggerTarget && !A.triggerTarget
          ? Ae(b.triggerTarget).forEach(function (O) {
              O.removeAttribute("aria-expanded");
            })
          : A.triggerTarget && t.removeAttribute("aria-expanded"),
        J(),
        I(),
        T && T(b, A),
        c.popperInstance &&
          (Xe(),
          ce().forEach(function (O) {
            requestAnimationFrame(O._tippy.popperInstance.forceUpdate);
          })),
        R("onAfterUpdate", [c, f]);
    }
  }
  function Ze(f) {
    c.setProps({ content: f });
  }
  function Ar() {
    var f = c.state.isVisible,
      b = c.state.isDestroyed,
      A = !c.state.isEnabled,
      O = ee.isTouch && !c.props.touch,
      x = gt(c.props.duration, 0, G.duration);
    if (
      !(f || b || A || O) &&
      !D().hasAttribute("disabled") &&
      (R("onShow", [c], !1), c.props.onShow(c) !== !1)
    ) {
      if (
        ((c.state.isVisible = !0),
        M() && (m.style.visibility = "visible"),
        I(),
        X(),
        c.state.isMounted || (m.style.transition = "none"),
        M())
      ) {
        var $ = F(),
          z = $.box,
          Le = $.content;
        yt([z, Le], 0);
      }
      (g = function () {
        var he;
        if (!(!c.state.isVisible || u)) {
          if (
            ((u = !0),
            m.offsetHeight,
            (m.style.transition = c.props.moveTransition),
            M() && c.props.animation)
          ) {
            var ft = F(),
              et = ft.box,
              Ee = ft.content;
            yt([et, Ee], x), Qt([et, Ee], "visible");
          }
          K(),
            J(),
            Kt(bt, c),
            (he = c.popperInstance) == null || he.forceUpdate(),
            R("onMount", [c]),
            c.props.animation &&
              M() &&
              oe(x, function () {
                (c.state.isShown = !0), R("onShown", [c]);
              });
        }
      }),
        se();
    }
  }
  function _r() {
    var f = !c.state.isVisible,
      b = c.state.isDestroyed,
      A = !c.state.isEnabled,
      O = gt(c.props.duration, 1, G.duration);
    if (!(f || b || A) && (R("onHide", [c], !1), c.props.onHide(c) !== !1)) {
      if (
        ((c.state.isVisible = !1),
        (c.state.isShown = !1),
        (u = !1),
        (a = !1),
        M() && (m.style.visibility = "hidden"),
        de(),
        ne(),
        I(!0),
        M())
      ) {
        var x = F(),
          $ = x.box,
          z = x.content;
        c.props.animation && (yt([$, z], O), Qt([$, z], "hidden"));
      }
      K(), J(), c.props.animation ? M() && we(O, c.unmount) : c.unmount();
    }
  }
  function Tr(f) {
    W().addEventListener("mousemove", E), Kt(nt, E), E(f);
  }
  function xr() {
    c.state.isVisible && c.hide(),
      c.state.isMounted &&
        (Ge(),
        ce().forEach(function (f) {
          f._tippy.unmount();
        }),
        m.parentNode && m.parentNode.removeChild(m),
        (bt = bt.filter(function (f) {
          return f !== c;
        })),
        (c.state.isMounted = !1),
        R("onHidden", [c]));
  }
  function Or() {
    c.state.isDestroyed ||
      (c.clearDelayTimeouts(),
      c.unmount(),
      Ue(),
      delete t._tippy,
      (c.state.isDestroyed = !0),
      R("onDestroy", [c]));
  }
}
function Ce(t, e) {
  e === void 0 && (e = {});
  var r = G.plugins.concat(e.plugins || []);
  tn();
  var i = Object.assign({}, e, { plugins: r }),
    n = Ki(t),
    o = n.reduce(function (a, s) {
      var l = s && fn(s, i);
      return l && a.push(l), a;
    }, []);
  return dt(t) ? o[0] : o;
}
Ce.defaultProps = G;
Ce.setDefaultProps = ln;
Ce.currentInput = ee;
Object.assign({}, lr, {
  effect: function (e) {
    var r = e.state,
      i = {
        popper: {
          position: r.options.strategy,
          left: "0",
          top: "0",
          margin: "0",
        },
        arrow: { position: "absolute" },
        reference: {},
      };
    Object.assign(r.elements.popper.style, i.popper),
      (r.styles = i),
      r.elements.arrow && Object.assign(r.elements.arrow.style, i.arrow);
  },
});
Ce.setDefaultProps({ render: Er });
const pn = () => {
    function t({ localStorageTheme: s, systemSettingDark: l }) {
      return s !== null ? s : l.matches ? "dark" : "light";
    }
    function e({ buttonEl: s, isDark: l }) {
      const u = l ? "Change to light theme" : "Change to dark theme";
      s.setAttribute("aria-label", u), s.setAttribute("title", u);
      const d = document.querySelectorAll("[data-theme-label]");
      d.length > 0 &&
        d.forEach((p) => {
          p.textContent = l ? "Dark mode" : "Light mode";
        });
    }
    function r({ theme: s }) {
      var l;
      (l = document.querySelector("html")) == null ||
        l.setAttribute("data-theme", s);
    }
    const i = document.querySelectorAll("[data-theme-toggle]");
    if (i.length === 0) return;
    const n = localStorage.getItem("theme"),
      o = window.matchMedia("(prefers-color-scheme: dark)");
    let a = t({ localStorageTheme: n, systemSettingDark: o });
    r({ theme: a }),
      i.forEach((s) => {
        e({ buttonEl: s, isDark: a === "dark" }),
          s.addEventListener("click", (l) => {
            const u = a === "dark" ? "light" : "dark";
            localStorage.setItem("theme", u),
              i.forEach((d) => {
                e({ buttonEl: d, isDark: u === "dark" });
              }),
              r({ theme: u }),
              (a = u);
          });
      });
  },
  hn = () => {
    const t = document.querySelector(".announcement-bar__message");
    if (t) {
      const a = localStorage.getItem("announcementRead");
      if (a && JSON.parse(a).includes(t.innerText)) {
        const u = document.querySelector(".announcement-bar-section");
        u == null || u.remove(), window.dispatchEvent(new Event("resize"));
      }
      const s = document.querySelector(".announcement-bar__close");
      s &&
        s.addEventListener("click", (l) => {
          l.preventDefault(),
            t.classList.add("announcement-bar__message--read"),
            localStorage.setItem(
              "announcementRead",
              JSON.stringify([t.innerText])
            ),
            setTimeout(() => {
              const u = document.querySelector(".announcement-bar-section");
              u == null || u.remove(),
                window.dispatchEvent(new Event("resize"));
            }, 500);
        });
    }
    const e = document.querySelector(".header-group"),
      r = document.querySelector(".header-group"),
      i = document.querySelector(".menu-drawer"),
      n = document.querySelector(".header__menu-button");
    if (e) {
      const a = () => {
        const s = e.clientHeight;
        document.body.style.paddingTop = `${s}px`;
      };
      window.addEventListener("resize", () => {
        a();
      }),
        setTimeout(() => {
          a();
        }, 100),
        document.fonts.ready.then(function () {
          a();
        });
    }
    n &&
      n.addEventListener("click", (a) => {
        if (
          (a.preventDefault(),
          n.classList.contains("header__menu-button--open"))
        ) {
          n.classList.remove("header__menu-button--open"),
            n.focus(),
            r && r.classList.remove("header-group--open-menu"),
            i.classList.remove("menu-drawer--open"),
            i.setAttribute("aria-hidden", "true"),
            i.setAttribute("tabindex", "-1"),
            document.body.classList.remove("overflow-hidden"),
            typeof window.lenis < "u" && lenis.start();
          return;
        } else
          n.classList.add("header__menu-button--open"),
            r == null || r.classList.add("header-group--open-menu"),
            i == null || i.classList.add("menu-drawer--open"),
            i == null || i.setAttribute("aria-hidden", "false"),
            i == null || i.setAttribute("tabindex", "0"),
            document.body.classList.add("overflow-hidden"),
            typeof window.lenis < "u" && lenis.stop();
      }),
      window.addEventListener("resize", () => {
        window.innerWidth < 1024 ||
          (n.classList.remove("header__menu-button--open"),
          r && r.classList.remove("header-group--open-menu"),
          i.classList.remove("menu-drawer--open"),
          i.setAttribute("aria-hidden", "true"),
          i.setAttribute("tabindex", "-1"),
          document.body.classList.remove("overflow-hidden"),
          typeof window.lenis < "u" && lenis.start());
      });
    const o = document.querySelector(".header__search-toggle");
    o &&
      o.addEventListener("click", (a) => {
        a.preventDefault();
        const s = document.querySelector(".header__search-inline"),
          l = document.querySelector(".header__search-inline input");
        if (s.classList.contains("header__search--open")) {
          s.classList.remove("header__search--open"),
            l &&
              ((l.value = ""),
              l.dispatchEvent(new Event("input", { bubbles: !0 })));
          return;
        } else s.classList.add("header__search--open"), l && l.focus();
      }),
      window.addEventListener("keydown", (a) => {
        const s = document.querySelector(".header__search-inline"),
          l = document.querySelector(".header__search-inline input");
        a.key === "Escape" &&
          s.classList.contains("header__search--open") &&
          (s.classList.remove("header__search--open"),
          l &&
            ((l.value = ""),
            l.dispatchEvent(new Event("input", { bubbles: !0 }))));
      });
  },
  vn = () => {
    const t = document.querySelector("#ContactFooter"),
      e = async (n) => {
        t.classList.add("loading"),
          n.preventDefault(),
          localStorage.setItem("newsletter-popout-hidden", "true");
        const o = document.querySelector("#footer-modal");
        if (o) {
          const a = o.querySelector('input[name="email"]');
          a &&
            (a.value = document.querySelector("#NewsletterForm--footer").value);
          const s = o.querySelector("#footer-modal-thanks"),
            l = o.querySelector("#footer-modal-content");
          l && (l.classList.remove("hidden"), s && s.classList.add("hidden")),
            Ot.show("footer-modal");
        }
        t.classList.remove("loading");
      };
    t.addEventListener("submit", e);
    const r = document.querySelector("#footer-modal form");
    r &&
      r.addEventListener("submit", async (n) => {
        n.preventDefault(),
          localStorage.setItem("newsletter-popout-hidden", "true");
        const o = document.querySelector("#footer-modal-thanks"),
          a = document.querySelector("#footer-modal-content");
        a && a.classList.add("hidden"), o && o.classList.remove("hidden");
        const s = new FormData(n.target),
          l = {};
        s.forEach(function (d, p) {
          l[p] = d;
        }),
          document.querySelectorAll('input[type="email"]').forEach((d) => {
            d.value = "";
          }),
          await fetch("https://signup.wmg.com/register", {
            method: "POST",
            body: JSON.stringify(l),
            mode: "no-cors",
            headers: { "Content-Type": "application/json" },
          });
      });
    const i = document.querySelector('select[name="country"]');
    if (
      i &&
      (i.addEventListener("change", (n) => {
        const o = document.querySelector(".au-state");
        o &&
          (n.target.value === "AU"
            ? o.classList.remove("hidden")
            : (o.classList.add("hidden"),
              (o.querySelector("select").value = "")));
      }),
      i.value === "AU")
    ) {
      const n = document.querySelector(".au-state");
      n && n.classList.remove("hidden");
    }
  },
  St = "<p>Looks like there is nothing currently in your wishlist.</p>";
function mn(t) {
  const e = `/products/${t}`;
  fetch(e)
    .then((r) => r.text())
    .then((r) => {
      const n = new DOMParser().parseFromString(r, "text/html"),
        o = n.querySelector(".product__info .price").innerHTML,
        a = JSON.parse(localStorage.getItem("wishlist")) || [],
        s = a.find((p) => p.handle === t),
        l = n.querySelector(".product__info .badges");
      s &&
        ((s.price = o),
        (s.last_updated_price = Date.now()),
        (s.tags = l ? l.innerHTML : ""),
        (s.on_sale = n
          .querySelector(".product__info .price")
          .classList.contains("price--on-sale")),
        localStorage.setItem("wishlist", JSON.stringify(a)));
      const d = document
        .querySelector("#wishlist .wishlist-list")
        .querySelector(`[data-handle="${t}"]`);
      d &&
        ((d.querySelector(".price").innerHTML = o),
        (d.querySelector(".badges").innerHTML = l ? l.innerHTML : ""),
        s.on_sale
          ? d.querySelector(".price").classList.add("price--on-sale")
          : d.querySelector(".price").classList.remove("price--on-sale"));
    });
}
const Lt = (t) => {
    const e = document.querySelector("#wishlist-count");
    e &&
      ((e.querySelector("span").innerHTML = t),
      t > 0 ? e.classList.remove("hidden") : e.classList.add("hidden"));
  },
  gn = () => {
    const t = JSON.parse(localStorage.getItem("wishlist")) || [],
      e = document.querySelector("#wishlist .wishlist-list");
    if ((Lt(t.length), e))
      if (t.length === 0) e.innerHTML = St;
      else {
        const n = t
          .map((o) =>
            o.image
              ? ((window.location.hash.indexOf("update") !== !1 ||
                  !o.last_updated_price ||
                  Date.now() - o.last_updated_price > 864e5) &&
                  mn(o.handle),
                `
            <li class="product-grid__item" data-handle="${o.handle}">
            <div class="card-wrapper product-card-wrapper">
              <div class="badges">${o.tags || ""}</div>
              <div class="card card--media">
                <div class="wishlist-item__content">
                  <div class="card__inner">
                    <a href="/products/${
                      o.handle
                    }" class="wishlist-item__image">
                      <div class="card__media">
                        <img src="${o.image}" alt="${
                  o.title
                }" loading="lazy" width="430" height="430" />
                      </div>
                    </a>

                    <div class="card__content">
                      <a href="/products/${o.handle}" class="card__information">
                        <p class="body-md card__artist">${o.title}</p>

                        <h3 class="card__heading heading-md">${
                          o.description || ""
                        }</h3>

                        <div class="price ${
                          o.on_sale === !0 ? "price--on-sale" : ""
                        }">${o.price || ""}</div>
                      </a>

                      <button
                        class="card__wishlist wishlist-trigger"
                        data-product-handle="${o.handle}"
                        data-product-image="${o.image}"
                        data-product-title="${o.title}"
                        data-product-description="${o.description}"
                      >

                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="1"
                        stroke-linecap="square"
                        aria-hidden="true"
                        stroke-linejoin="round"
                      >
                        <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                      </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          `)
              : null
          )
          .filter((o) => o !== null)
          .join("");
        n ? (e.innerHTML = n) : (e.innerHTML = St);
      }
    const r = (n) => {
      const o = n.classList.contains("wishlist-trigger--active"),
        a = n.getAttribute("data-product-handle");
      if (o) {
        n.classList.remove("wishlist-trigger--active");
        const d = (JSON.parse(localStorage.getItem("wishlist")) || []).filter(
          (p) => p.handle !== a
        );
        if (
          (localStorage.setItem("wishlist", JSON.stringify(d)), Lt(d.length), e)
        ) {
          const p = n.closest("li");
          (p.style.opacity = 0),
            setTimeout(() => {
              p.remove(), e.querySelector("li") || (e.innerHTML = St);
            }, 600);
        }
        return;
      }
      const s = {
          handle: a,
          tags: "",
          image: n.getAttribute("data-product-image"),
          title: n.getAttribute("data-product-title"),
          description: n.getAttribute("data-product-description"),
        },
        l = JSON.parse(localStorage.getItem("wishlist")) || [];
      l.push(s),
        localStorage.setItem("wishlist", JSON.stringify(l)),
        Lt(l.length),
        n.classList.add("wishlist-trigger--active"),
        window.location.pathname.indexOf("pages/wishlist") > -1 &&
          window.location.reload();
    };
    document.addEventListener("click", (n) => {
      n.target.classList.contains("wishlist-trigger") &&
        (n.preventDefault(), r(n.target));
    }),
      Array.from(document.querySelectorAll(".wishlist-trigger")).forEach(
        (n) => {
          const o = n.getAttribute("data-product-handle");
          t.find((a) => a.handle === o) &&
            (n.classList.add("wishlist-trigger--active"),
            n.classList.contains("button"));
        }
      );
  },
  Ie = () => {
    const t = new URL(window.location.href),
      e = new URLSearchParams(t.search),
      r = document.querySelector("#sale");
    e.delete("filter.p.tag"),
      r &&
        r.checked &&
        (e.append("filter.p.tag", "Sale"),
        e.append("filter.p.tag", "2022-Sale"),
        e.append("filter.p.tag", "Sale-2021"));
    const i = document.querySelector("#preorder");
    i && i.checked && e.append("filter.p.tag", "Page:POrder");
    const n = document.querySelector(".collection__facets");
    if (n) {
      let a = `${n.dataset.baseHref}/?${e.toString()}`;
      history.pushState({}, "", a);
      const s = document.querySelector("#ProductGridContainer");
      s.classList.add("loading"),
        fetch(a)
          .then((l) => l.text())
          .then((l) => {
            const p = new DOMParser()
              .parseFromString(l, "text/html")
              .querySelector("#ProductGridContainer");
            p && (s.classList.remove("loading"), (s.innerHTML = p.innerHTML));
          });
    } else if (document.querySelector(".artists")) {
      const o = new CustomEvent("updateArtists", {});
      document.dispatchEvent(o);
    } else {
      const o = new CustomEvent("updateSearch", {
        detail: { params: `?${e.toString()}` },
      });
      document.dispatchEvent(o);
    }
  },
  ot = (t) => {
    const e = Array.from(
        t.popper.querySelectorAll('input[type="checkbox"]')
      ).length,
      r = new URL(window.location.href),
      i = new URLSearchParams(r.search);
    if (e > 0) {
      const n = t.popper.querySelectorAll("input:checked"),
        o = t.popper.querySelector("input").name;
      i.delete(o),
        n.forEach((a) => {
          i.append(o, a.value);
        });
    } else {
      const n = t.popper.querySelector("input[name='filter.v.price.gte']"),
        o = t.popper.querySelector("input[name='filter.v.price.lte']");
      n && n.value && i.set("filter.v.price.gte", n.value),
        o && o.value && i.set("filter.v.price.lte", o.value);
      const a = t.popper.querySelector(".facet__pill-option--selected");
      a ? i.set("sort_by", a.dataset.value) : i.delete("sort_by");
    }
    (r.search = i.toString()), history.pushState({}, "", r);
  },
  _e = (t = null) => {
    document.querySelectorAll(".facet__pill").forEach((n) => {
      if (t && t.reference !== n) return;
      let o = n.querySelector(".facet__pill-label"),
        a = null,
        s = null,
        l = !1;
      if (t)
        if (
          ((l = Array.from(
            t.popper.querySelectorAll('input[type="checkbox"]')
          ).length),
          l)
        )
          (s = t.popper.querySelectorAll("input:checked").length),
            s > 0 && (a = `(${s})`);
        else {
          const u = t.popper.querySelector("input[name='filter.v.price.gte']"),
            d = t.popper.querySelector("input[name='filter.v.price.lte']");
          u && d && u.value && d.value
            ? (s = u.value + " - " + d.value)
            : u && u.value
            ? (s = u.value + "+")
            : d && d.value && (s = "< " + d.value),
            document
              .querySelectorAll("input[name='filter.v.price.gte']")
              .forEach((v) => {
                v.value = u ? u.value : "";
              }),
            document
              .querySelectorAll("input[name='filter.v.price.lte']")
              .forEach((v) => {
                v.value = d ? d.value : "";
              });
        }
      else if (
        ((l = n.querySelectorAll('input[type="checkbox"]').length), l > 0)
      )
        (s = n.querySelectorAll("input:checked").length),
          s > 0 && (a = `(${s})`);
      else {
        s = "";
        const u = n.querySelector("input[name='filter.v.price.gte']"),
          d = n.querySelector("input[name='filter.v.price.gte']");
        u && d && u.value && d.value
          ? (s = "$" + u.value + " - $" + d.value)
          : u && u.value
          ? (s = "$" + u.value + "+")
          : d && d.value && (s = "< $" + d.value),
          s !== "" && (a = `(${s})`);
      }
      if (o)
        if (
          (o.innerHTML.includes("(") &&
            (o.innerHTML = o.innerHTML.substring(0, o.innerHTML.indexOf("("))),
          a)
        ) {
          n.classList.add("facet__pill--active");
          const u = o.innerText.trim();
          (o.innerText = `${u} ${a}`),
            console.log("Adding active class", o, `${u} ${a}`);
        } else n.classList.remove("facet__pill--active");
    });
    const r = document.querySelector(
      'button[data-template="sort-options"] .facet__pill-label'
    );
    if (r) {
      let n = document.querySelector(
        "#sort-options a.facet__pill-option--selected"
      );
      t && (n = t.popper.querySelector(".facet__pill-option--selected")),
        n
          ? (r.innerHTML = `Sort (${n.innerText.trim()})`)
          : (r.innerHTML = "Sort");
    }
    const i = new URL(window.location.href);
    if (i.search.includes("POrder")) {
      const n = document.querySelector("#preorder");
      n && (n.checked = !0);
    }
    if (i.search.includes("p.tag=Sale")) {
      const n = document.querySelector("#sale");
      n && (n.checked = !0);
    }
  },
  yn = () => {
    const t = document.querySelector(
      ".collection-hero--with-image.collection-hero--basic .collection-hero__image"
    );
    if (t) {
      const a = () => {
        const s = window.scrollY;
        (t.style.transform = `translateY(${s * 0.3}px)`),
          (t.style.opacity = 1 - s * 0.001);
      };
      window.addEventListener("scroll", a);
    }
    _e();
    const e = document.querySelectorAll(".collection-hero__product"),
      r = (a) => {
        a.classList.add("active"),
          typeof window.lenis < "u"
            ? window.lenis.scrollTo(0)
            : window.scrollTo(0, 0);
        const s = a.dataset.bannerIndex;
        document.querySelectorAll(".collection-hero__images").forEach((u) => {
          u.dataset.bannerIndex == s
            ? u.classList.remove("collection-hero__images--hidden")
            : u.classList.add("collection-hero__images--hidden");
        });
      },
      i = document.querySelector(".collection-hero__nav-button--next");
    let n = !1;
    i &&
      i.addEventListener("click", () => {
        if (n) return;
        n = !0;
        const a = document.querySelector(".collection-hero__product.active");
        let s = null;
        a.classList.remove("active"),
          a.nextElementSibling &&
          a.nextElementSibling.classList.contains("collection-hero__product")
            ? (s = a.nextElementSibling)
            : (s = e[0]),
          setTimeout(() => {
            r(s),
              setTimeout(() => {
                n = !1;
              }, 500);
          }, 500);
      });
    const o = document.querySelector(".collection-hero__nav-button--prev");
    if (o) {
      o.addEventListener("click", () => {
        if (n) return;
        n = !0;
        const l = document.querySelector(".collection-hero__product.active");
        let u = null;
        l.classList.remove("active"),
          l.previousElementSibling &&
          l.previousElementSibling.classList.contains(
            "collection-hero__product"
          )
            ? (u = l.previousElementSibling)
            : (u = e[e.length - 1]),
          setTimeout(() => {
            u && r(u),
              setTimeout(() => {
                n = !1;
              }, 500);
          }, 500);
      }),
        document.querySelectorAll(".collection-hero__dots a").forEach((l) => {
          l.addEventListener("click", (u) => {
            u.preventDefault();
            const d = document.querySelector(
              ".collection-hero__images:not(.collection-hero__images--hidden)"
            );
            u.target.classList.contains("show-secondary")
              ? (d.scrollLeft = d.scrollWidth)
              : (d.scrollLeft = 0),
              u.target
                .closest(".collection-hero__dots")
                .querySelectorAll("a")
                .forEach((g) => {
                  g.classList.remove("active");
                }),
              u.target.classList.add("active");
          });
        });
      const s = document.querySelectorAll(".collection-hero__images");
      s &&
        s.forEach((l) => {
          const u = (p) => {
              const g = p.target.scrollLeft,
                v = l.nextElementSibling,
                E = v.querySelector(".show-secondary"),
                w = v.querySelector("a:not(.show-secondary)");
              g > 100
                ? (w.classList.remove("active"), E.classList.add("active"))
                : (w.classList.add("active"), E.classList.remove("active"));
            },
            d = debounce(u, 50);
          l.addEventListener("scroll", d);
        });
    }
  },
  wn = (t) => {
    const { popper: e } = t;
    document.querySelectorAll(".facets__toggle").forEach((s) => {
      s.dataset.hasBinded ||
        (s.addEventListener("change", (l) => {
          Ie();
        }),
        (s.dataset.hasBinded = !0));
    });
    const i = e.querySelectorAll("#sort-options a");
    i &&
      i.forEach((s) => {
        s.addEventListener("click", (l) => {
          l.preventDefault(), _e(t), ot(t), Ie();
        });
      });
    const n = e.querySelectorAll(".facet__footer-close");
    n &&
      n.forEach((s) => {
        s.addEventListener("click", () => {
          _e(t), ot(t), Ie(), t.hide();
        });
      });
    const o = e.querySelectorAll(".facet__pill-option");
    o &&
      o.forEach((s) => {
        s.addEventListener("click", () => {
          s
            .closest(".tippy-content")
            .querySelectorAll(".facet__pill-option")
            .forEach((d) => {
              d !== s && d.classList.remove("facet__pill-option--selected");
            }),
            s.classList.toggle("facet__pill-option--selected"),
            ot(t),
            _e(t),
            Ie(),
            t.hide();
        });
      });
    const a = e.querySelector(".facet__clear");
    a &&
      a.addEventListener("click", (s) => {
        s.preventDefault();
        const l = a.closest(".tippy-content");
        l.querySelectorAll("input:checked").forEach((p) => {
          p.checked = !1;
        }),
          l.querySelectorAll('input[type="number"]').forEach((p) => {
            p.value = "";
          }),
          _e(t),
          ot(t),
          Ie(),
          t.hide();
      });
  },
  bn = (t) => {
    const e = new URL(window.location.href),
      r = new URLSearchParams(e.search);
    let i = !1;
    t.popper.querySelectorAll("input[name=genre]").length > 0
      ? (i = r.getAll("genre"))
      : t.popper.querySelectorAll("input[name=label]").length > 0 &&
        (i = r.getAll("label")),
      i &&
        (i.forEach((n) => {
          const o = t.popper.querySelector(`input[value="${n}"]`);
          o && (o.checked = !0);
        }),
        _e(t));
  },
  Sn = () => {
    const t = document.querySelector(".slider__items"),
      e = document.querySelector(".slider__wrapper");
    if (!t || !e) return;
    const r = t.querySelector(".slider__slide");
    let i = !1,
      n = 0,
      o = 0,
      a = 16,
      s = 0,
      l = 0,
      u = 0,
      d = 0,
      p = !1;
    const g = () => {
      (u = Math.abs(t.offsetWidth - e.offsetWidth)),
        (l = r.offsetWidth),
        (d = Math.ceil(u / l));
    };
    window.addEventListener("resize", () => {
      g();
    }),
      g();
    let v = 0;
    t.addEventListener("wheel", (h) => {
      if ((h.preventDefault(), !h.deltaX || (h.deltaX < 3 && h.deltaX > -3)))
        return;
      window.lenis.stop();
      let y = h.deltaX * 0.4,
        L = v + y * -1;
      return (
        L > 0 && (L = 0),
        L < u * -1 && (L = u * -1),
        (v = L),
        (t.style.transform = `translate3d(${L}px, 0, 0)`),
        window.lenis.start(),
        !1
      );
    });
    const E = (h) => {
        if (p) return;
        let y = 0,
          L = 0;
        h instanceof TouchEvent
          ? ((y = h.touches[0].pageX), (L = h.touches[0].pageY))
          : ((y = h.pageX), (L = h.pageY)),
          t.classList.remove("active");
        let c = Math.abs(n - y);
        if (Math.abs(o - L) > c) return !1;
        if (c < 12) return h.preventDefault(), !1;
        if ((h.preventDefault(), (p = !0), n > y)) {
          if (s < d) {
            s += 1;
            let m = s * l + (s - 1) * a;
            m > u && (m = u),
              (t.style.transform = `translateX(-${m}px)`),
              (n = y);
          }
        } else if (s > 0) {
          s -= 1;
          let m = 0;
          s > 0 && (m = s * l + (s - 1) * a),
            (n = y),
            (t.style.transform = `translateX(-${m}px)`);
        }
        return (
          setTimeout(() => {
            p = !1;
          }, 300),
          !1
        );
      },
      w = (h) => {
        (i = !0),
          t.classList.add("active"),
          h instanceof TouchEvent
            ? ((n = h.touches[0].pageX - t.offsetLeft),
              (o = h.touches[0].pageY - t.offsetTop))
            : ((n = h.pageX - t.offsetLeft), (o = h.pageY - t.offsetTop));
      },
      S = (h) => {
        (i = !1), t.classList.remove("active");
      };
    t.addEventListener("mousedown", (h) => {
      w(h);
    }),
      t.addEventListener("mouseup", (h) => {
        E(h);
      }),
      t.addEventListener("mouseleave", (h) => {
        S();
      }),
      t.addEventListener(
        "touchstart",
        (h) => {
          h.preventDefault(),
            w(h),
            setTimeout(() => {
              i == !1 && !p && h.target.tagName.toLowerCase();
            }, 200);
        },
        { passive: !0 }
      ),
      t.addEventListener("touchmove", (h) => E(h), { passive: !0 }),
      t.addEventListener("touchend", (h) => {
        S();
      }),
      t.addEventListener("touchcancel", (h) => {
        S();
      }),
      t.addEventListener("mousemove", (h) => {
        i && h.preventDefault();
      });
  },
  Ln = () => {
    const t = document.querySelector(".featured-search__form input"),
      e = document.querySelector(".featured-search__form"),
      r = document.querySelector(".featured-search__list");
    if (!t || !e || t.closest(".artists") || t.closest(".template-search"))
      return;
    const i = document.querySelector(".featured-search__form button");
    i &&
      i.addEventListener("click", () => {
        (t.value = ""),
          e.classList.remove("featured-search__form--active"),
          t.focus();
      });
    const n = () => {
      const a = t.value;
      fetch(`/search/suggest?q=${a}&section_id=predictive-search`)
        .then((s) => s.text())
        .then((s) => {
          if (a !== t.value) return;
          r.innerHTML = "";
          const d = new DOMParser()
            .parseFromString(s, "text/html")
            .querySelectorAll("[data-artist]");
          if (d.length > 0)
            d.forEach((p, g) => {
              r.appendChild(p),
                p.addEventListener("keydown", (v) => {
                  if (v.keyCode === 27) {
                    v.preventDefault(),
                      (t.value = ""),
                      e.classList.remove("featured-search__form--active");
                    return;
                  }
                  v.keyCode === 38 &&
                    (v.preventDefault(), g == 0 ? t.focus() : d[g - 1].focus()),
                    v.keyCode === 40 &&
                      (v.preventDefault(),
                      g < d.length - 1 && d[g + 1].focus());
                });
            });
          else {
            const p = document.createElement("div");
            p.classList.add("featured-search__empty"),
              (p.innerText = "No results found"),
              r.appendChild(p);
          }
          e.classList.remove("featured-search__form--loading");
        })
        .catch((s) => {
          console.error(s),
            e.classList.remove("featured-search__form--loading");
        });
    };
    t.addEventListener("focus", () => {
      if (!t.value) {
        e.classList.remove("featured-search__form--active"),
          (e.querySelector("input").value = "");
        return;
      }
      e.classList.add("featured-search__form--active");
    }),
      t.addEventListener("keydown", (a) => {
        (a.keyCode === 40 || a.keyCode === 9) &&
          (a.preventDefault(),
          r.firstElementChild && r.firstElementChild.focus());
      }),
      t.addEventListener("input", (a) => {
        if (!t.value) {
          e.classList.remove("featured-search__form--active"),
            (e.querySelector("input").value = "");
          return;
        }
        e.classList.add("featured-search__form--active"),
          e.classList.add("featured-search__form--loading");
      }),
      t.addEventListener("input", debounce(n, 300));
    function o(a) {
      a.target.closest(".featured-search");
    }
    document.addEventListener("focus", o, !0);
  },
  En = () => {
    let t = !1,
      e = !1;
    window.innerWidth < 1025 && (e = !0);
    const r = document.querySelectorAll(".banner__featured-image");
    if (!r.length) return;
    const i = (a) => {
        let s = document.querySelector(".banner__featured-slide--active");
        s &&
          (s.classList.remove("banner__featured-slide--active"),
          s.classList.add("banner__featured-slide--preview"),
          s.setAttribute("aria-hidden", "true"),
          s.setAttribute("tabindex", "-1")),
          a.classList.add("banner__featured-slide--active"),
          a.classList.remove("banner__featured-slide--preview"),
          a.removeAttribute("aria-hidden"),
          a.setAttribute("tabindex", "0");
      },
      n = (a, s) => {
        if ((a.preventDefault(), t)) return;
        t = !0;
        let l = s.closest(".banner__featured-slide");
        if (l.classList.contains("banner__featured-slide--active")) {
          t = !1;
          return;
        } else i(l);
        setTimeout(() => {
          t = !1;
        }, 500);
      },
      o = setInterval(() => {
        if ((window.innerWidth < 1025 && (e = !0), e)) {
          e = !1;
          return;
        }
        if (t) return;
        let s = document.querySelector(
          ".banner__featured-slide--active"
        ).nextElementSibling;
        s || (s = document.querySelector(".banner__featured-slide")), i(s);
      }, 8e3);
    r.forEach((a) => {
      a.addEventListener("click", (s) => {
        (e = !0), clearInterval(o), n(s, a);
      });
    });
  };
class An extends HTMLElement {
  connectedCallback() {
    this.classList.add("loading-animation"),
      (this.innerHTML = `
            <div></div><div></div><div></div><div></div><div></div>
        `);
  }
}
customElements.define("loading-component", An);
const Et = (t) => {
    if (!t) return "";
    let e = t.replace(/[^a-zA-Z0-9]/g, "");
    return (e = e.replace(/\s/g, "")), e;
  },
  _n = (t, e, r, i) => {
    let n = Et(e);
    const o = Et(t.getAttribute("data-name").toLowerCase()),
      a = Et(t.getAttribute("data-search-context").toLowerCase());
    let s = !0;
    return (
      r.length > 0 &&
        ((s = !1),
        t.getAttribute("data-genres") &&
          t
            .getAttribute("data-genres")
            .split(",")
            .forEach((u) => {
              r.indexOf(u) > -1 && (s = !0);
            })),
      s &&
        i.length > 0 &&
        t.getAttribute("data-labels") &&
        t
          .getAttribute("data-labels")
          .split(",")
          .forEach((u) => {
            i.indexOf(u) > -1 && (s = !0);
          }),
      n &&
        s &&
        ((s = !1), (o.indexOf(n) > -1 || a.indexOf(n) > -1) && (s = !0)),
      s
    );
  },
  Tn = () => {
    const t = document.querySelector(".artists"),
      e = document.querySelector(".artists__grid"),
      r = document.querySelector(".header-group"),
      i = document.querySelector(".artists__header");
    if (!t || !e) return;
    let n = Array.from(t.querySelectorAll(".artists__title")),
      o = Array.from(t.querySelectorAll(".artists__grid-item"));
    const a = Array.from(t.querySelectorAll(".artists__header-abc button")),
      s = (p) => {
        const g = p.target.getAttribute("data-letter"),
          v = t.querySelector(`.artists__title[data-letter="${g}"]`);
        if (v) {
          const E = r.offsetHeight + i.offsetHeight - 10,
            w = v.getBoundingClientRect().top + window.scrollY - E;
          window.scrollTo({ top: w, behavior: "smooth" });
        }
      };
    a.forEach((p) => {
      p.addEventListener("click", s);
    }),
      fetch("/collections?page=2")
        .then((p) => p.text())
        .then((p) => {
          const v = new DOMParser().parseFromString(p, "text/html");
          Array.from(v.querySelectorAll(".artists__grid-item")).forEach((h) => {
            e.appendChild(h);
          });
          const w = Array.from(t.querySelectorAll(".artists__grid-item"));
          w.sort((h, y) => {
            const L = h.getAttribute("data-letter"),
              c = y.getAttribute("data-letter");
            return L < c ? -1 : L > c ? 1 : 0;
          }),
            w.forEach((h) => {
              h.remove();
            });
          let S = "";
          w.forEach((h) => {
            const y = h.getAttribute("data-letter");
            if (y !== S) {
              const L = document.createElement("div");
              L.dataset.letter = y;
              const c = document.createElement("h2");
              L.classList.add("artists__title"),
                (c.innerHTML = y),
                L.appendChild(c),
                e.appendChild(L),
                (S = y);
            }
            e.appendChild(h);
          }),
            (n = Array.from(t.querySelectorAll(".artists__title"))),
            (o = Array.from(t.querySelectorAll(".artists__grid-item"))),
            setTimeout(() => {
              l(null), e.classList.remove("loading");
              const h = Array.from(t.querySelectorAll(".artists__title")),
                y = () => {
                  let _ = null;
                  if (
                    (h.forEach((m) => {
                      if (!_) {
                        _ = m;
                        return;
                      }
                      window.scrollY > m.dataset.scrollSpyPoint && (_ = m);
                    }),
                    _)
                  ) {
                    const m = _.dataset.letter,
                      T = t.querySelector(
                        `.artists__header-abc button[data-letter="${m}"]`
                      );
                    if (T) {
                      const q = t.querySelector(
                        ".artists__header-abc button.active"
                      );
                      q && q.classList.remove("active"),
                        T.classList.add("active");
                    }
                  }
                },
                L = () => {
                  const _ = r.offsetHeight + i.offsetHeight;
                  h.forEach((m) => {
                    const T =
                      m.getBoundingClientRect().top + window.scrollY - _;
                    m.dataset.scrollSpyPoint = T;
                  });
                };
              L(), window.addEventListener("resize", L);
              let c = null;
              window.addEventListener("scroll", () => {
                c && window.cancelAnimationFrame(c),
                  (c = window.requestAnimationFrame(y));
              });
            }, 100);
        });
    const l = (p) => {
        const g = new URL(window.location.href),
          v = new URLSearchParams(g.search),
          E = v.getAll("genre"),
          w = v.getAll("label");
        let S = [],
          h = !0;
        o.forEach((y) => {
          if (_n(y, p, E, w)) {
            y.classList.remove("hidden"), y.setAttribute("tabindex", "0");
            const c = y.getAttribute("data-letter");
            S.push(c), (h = !1);
          } else y.classList.add("hidden"), y.setAttribute("tabindex", "-1");
        }),
          n.forEach((y) => {
            const L = y.dataset.letter;
            S.indexOf(L) < 0
              ? y.classList.add("hidden")
              : y.classList.remove("hidden");
          }),
          a.forEach((y) => {
            const L = y.getAttribute("data-letter");
            S.indexOf(L) < 0
              ? y.setAttribute("disabled", "disabled")
              : y.removeAttribute("disabled");
          }),
          h
            ? e.classList.add("artist__grid--empty")
            : e.classList.remove("artist__grid--empty");
      },
      u = document.querySelector(".featured-search__input");
    if (u) {
      const p = (E) => {
          const w = E.target.value.toLowerCase(),
            S = r.offsetHeight + i.offsetHeight + 20,
            h = e.getBoundingClientRect().top + window.scrollY - S;
          window.scrollTo({ top: h, behavior: "instant" }), l(w);
        },
        g = window.innerWidth > 1e3 ? 200 : 700,
        v = debounce(p, g);
      u.addEventListener("input", v);
    }
    window.addEventListener("popstate", () => {
      l(u ? u.value : null);
    }),
      window.addEventListener("pushstate", () => {
        l(u ? u.value : null);
      });
    const d = document.querySelector(".featured-search__form button");
    d &&
      d.addEventListener(
        "click",
        (p) => (p.preventDefault(), (u.value = ""), l(null), !1)
      ),
      document.addEventListener("updateArtists", () => {
        l(u ? u.value : null);
      });
  },
  xn = () => {
    const t = document.querySelectorAll("[data-add-to-cart]");
    t &&
      t.forEach((e) => {
        e.addEventListener("click", (r) => {
          typeof _satellite < "u" && _satellite.track("cart add");
        });
      });
  },
  On = () => {
    if (!document.querySelector(".template-search")) return;
    const t = new AbortController();
    document.addEventListener("click", (r) => {
      if (r.target.matches('button[type="reset"]')) {
        r.preventDefault();
        const i = r.target.closest("form");
        if (i) {
          const n = i.querySelector('input[type="text"]');
          n &&
            ((n.value = ""),
            n.dispatchEvent(new Event("input", { bubbles: !0 })),
            n.focus());
        }
      }
    });
    const e = (r) => {
      const i = document.querySelector(".search-results__artists");
      if (!i) {
        r();
        return;
      }
      const n = new URLSearchParams(window.location.search);
      if (n.has("q")) {
        const o = n.get("q");
        fetch(
          `${routes.predictive_search_url}?q=${encodeURIComponent(
            o
          )}&section_id=predictive-search`,
          { signal: t.signal }
        )
          .then((a) => {
            if (!a.ok) {
              var s = new Error(a.status);
              throw s;
            }
            return a.text();
          })
          .then((a) => {
            const u = new DOMParser()
              .parseFromString(a, "text/html")
              .querySelectorAll("[data-artist]");
            if (u.length > 0) {
              (i.innerHTML = ""),
                u.forEach((p) => {
                  i.appendChild(p);
                }),
                i.classList.remove("hidden");
              const d = document.createElement("h2");
              d.classList.add("heading-lg"),
                (d.innerHTML = "Artists"),
                i.parentNode.insertBefore(d, i);
            }
            r();
          })
          .catch((a) => {
            console.error(a), r();
          });
      } else r();
    };
    document.addEventListener("updateSearch", (r) => {
      const { params: i } = r.detail;
      document
        .querySelector(".search-results")
        .classList.add("search-results--loading");
      let n = window.location.href;
      (n = n.split("?")[0]),
        (n += `${i}&q=${encodeURIComponent(
          document.querySelector(".featured-search__input").value
        )}`),
        history.pushState({}, "", n),
        fetch(n, { method: "GET" })
          .then((o) => o.text())
          .then((o) => {
            const s = new DOMParser().parseFromString(o, "text/html");
            (document.querySelector(".search-results").innerHTML =
              s.querySelector(".search-results").innerHTML),
              e(() => {
                document
                  .querySelector(".search-results")
                  .classList.remove("search-results--loading");
              });
          })
          .catch((o) => {
            console.error(o);
          });
    }),
      e(() => {});
  },
  qn = () => {
    const t = document.querySelector("#newsletter-popout");
    if (!t) {
      console.warn("No popout element found");
      return;
    }
    const e = localStorage.getItem("newsletter-popout-hidden");
    if (!new URLSearchParams(window.location.search).get("popout") && e) {
      console.warn("Popout element found but hidden");
      return;
    }
    let n = !1;
    const o = () => {
      const u =
        window.scrollY > window.innerHeight &&
        window.scrollY < document.body.clientHeight - window.innerHeight;
      u && !n
        ? (t.classList.remove("off-screen"), (n = !0))
        : !u && n && (t.classList.add("off-screen"), (n = !1));
    };
    o();
    const a = t.querySelector(".newsletter-popout__close");
    a &&
      a.addEventListener("click", () => {
        t.classList.add("off-screen"),
          localStorage.setItem(
            "newsletter-popout-hidden",
            new Date().getTime()
          );
      });
    const s = t.querySelector("form");
    s &&
      s.addEventListener("submit", (u) => {
        u.preventDefault();
        const d = document.querySelector("#footer-modal");
        if (d) {
          const p = d.querySelector('input[name="email"]');
          p && (p.value = s.querySelector(".input-text").value),
            MicroModal.show("footer-modal");
        }
        return !1;
      });
    let l;
    return {
      scroll: () => {
        clearTimeout(l), (l = setTimeout(o, 100));
      },
    };
  },
  Cn = () => {
    document.querySelectorAll(".rte table:not(.wrapped)").forEach((r) => {
      const i = document.createElement("div");
      i.classList.add("responsive-table"),
        r.classList.add("wrapped"),
        i.appendChild(r.cloneNode(!0));
      const n = document.createElement("div");
      n.classList.add("responsive-table-wrapper"),
        n.appendChild(i),
        r.parentNode.insertBefore(n, r),
        r.remove();
    }),
      document.querySelectorAll("p").forEach((r) => {
        r.innerHTML === "&nbsp;" && r.remove();
      });
  };
let nr = qn();
try {
  pn(), hn(), vn(), gn(), yn(), Sn(), Ln(), En(), Tn(), On(), Cn();
} catch (t) {
  console.error(t);
}
Ot.init({
  disableScroll: !0,
  onShow: (t) => {
    console.error("modal", t), lenis.stop();
  },
  onClose: (t) => {
    lenis.start();
  },
});
{
  let e = function (r) {
    t.raf(r), requestAnimationFrame(e);
  };
  var kn = e;
  const t = new Rr();
  t.on("scroll", (r) => {
    nr && nr.scroll();
  }),
    (window.lenis = t),
    requestAnimationFrame(e);
}
document.documentElement.className = document.documentElement.className.replace(
  "no-js",
  "js"
);
try {
  Ce("[data-tippy-content]", {
    dynamicTitle: !0,
    onShow(t) {
      setTimeout(() => {
        t.hide();
      }, 2e3);
    },
  }),
    Ce(".tippy", {
      content(t) {
        const e = t.getAttribute("data-template"),
          r = document.getElementById(e);
        return r ? r.innerHTML : "";
      },
      trigger: "click",
      interactive: !0,
      placement: "bottom-start",
      appendTo: document.body,
      allowHTML: !0,
      onShow(t) {
        const e = t.popper.querySelector(".tippy-close");
        e &&
          e.addEventListener("click", () => {
            t.hide();
          });
      },
      onCreate(t) {
        wn(t), bn(t);
      },
      onHide(t) {
        const e = t.popper.querySelector(".tippy-close");
        e &&
          e.removeEventListener("click", () => {
            t.hide();
          });
      },
    });
} catch (t) {
  console.error(t);
}
try {
  let e = function () {
    t.forEach((r) => {
      let i = r.parentNode;
      i != this.parentNode && i.removeAttribute("open");
    });
  };
  var Dn = e;
  const t = Array.from(
    document.querySelectorAll(".product__accordion summary")
  );
  t.forEach((r) => {
    r.addEventListener("click", e);
  });
} catch (t) {
  console.error(t);
}
try {
  document.querySelectorAll(".footer .input-text").forEach((e) => {
    e.addEventListener("focus", () => {
      e.closest("form").classList.remove("show-error");
    }),
      e.addEventListener("blur", () => {
        e.closest("form").classList.add("show-error");
      }),
      e.addEventListener("input", () => {
        e.value && e.closest("form").classList.remove("show-error");
      });
  });
} catch (t) {
  console.error(t);
}
xn();
